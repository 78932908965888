import React, { useState } from "react";
import { Input, Button, Text } from "@chakra-ui/react";

const SerialNumberGenerator = (props) => {
  const [seedSerial, setSeedSerial] = useState("");
  const [currentSerial, setCurrentSerial] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase(); // Ensure uppercase
    setSeedSerial(value);

    if (isValidSeed(value)) {
        props.onSave(value); //update the main component as well
    }
  };

  const isValidSeed = (seed) => {
    // Ensure the seed is 7 characters, first 3 alphabetic, last 4 numeric
    // Total : 16, 8 alphabets, 8 numeric
    const alphabeticPart = seed.slice(0, 8);
    const numericPart = seed.slice(8);
    return /^[A-Z]{8}$/.test(alphabeticPart) && /^[0-9]{8}$/.test(numericPart);
  };

  const generateNextSerial = () => {
    if (isValidSeed(seedSerial)) {
      const alphabeticPart = seedSerial.slice(0, 8); // First 3 characters
      const numericPart = parseInt(seedSerial.slice(8), 10); // Last 4 characters as number
      const nextNumber = numericPart + 1;

      // Pad the number with leading zeros to maintain 4 digits
      const nextSerial = `${alphabeticPart}${String(nextNumber).padStart(8, "0")}`;
      setCurrentSerial(nextSerial);
      setSeedSerial(nextSerial); // Update the seed for next generation

      props.onSave(nextSerial); //update the main component as well
      setError("");
    } else {
      setError("Invalid Seed. Must be 8 letters followed by 8 digits.");
    }
  };

  return (
    <div>
      <Input
        placeholder="Enter 16-character seed (e.g., ABCDEFGH12345678)"
        value={seedSerial}
        onChange={handleInputChange}
        maxLength={16}
      />
      <Button mt={4} onClick={generateNextSerial}>
        Generate Next Serial
      </Button>

      {error && (
        <Text color="red.500" mt={2}>
          {error}
        </Text>
      )}

      {currentSerial && <Text mt={4}>Next Serial Number: {currentSerial}</Text>}
    </div>
  );
};

export default SerialNumberGenerator;
