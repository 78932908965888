import { Card, CardBody, CardHeader } from "@chakra-ui/card";
import { Flex, Grid, GridItem, Heading, Spacer, Text } from "@chakra-ui/layout";
import React from "react";
import { MdBluetoothConnected, MdOutlineWifi } from "react-icons/md";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setActiveDevice } from "../redux/reducers/controllersReducer";

function DeviceWatchlist({ device_list }) {
  console.log(
    device_list,
    "device_listdevice_listdevice_listdevice_listdevice_list"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.controllers.devices);
  const user_type = useSelector((state) => state?.auth?.user_type);

  const styles = {
    watchlistCard: {
      borderRadius: 8,
      size: "sm",
      minHeight: "140px",
    },
    watchlistCardBody: {
      border: "1px",
      borderRadius: 6,
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
      mx: 3,
      mb: 3,
    },
    gridWatchlist: {
      templateColumns: { base: "repeat(4, 1fr)", lg: "repeat(3, 1fr)" },
      gap: 2,
      marginTop: 4,
    },
    headerFlex: {
      alignItems: "center",
      gap: 2,
    },
    heading: {
      size: 14,
      textTransform: "capital",
    },
    cardBodyFlex: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    greenIcon: {
      color: "#30C268",
    },
    pressureReading: {
      size: 16,
    },
    pressureThreshold: {
      color: "#929FB1",
    },
  };

  const onClickDevice = (selectedDevice) => {
    const completeDeviceInfo = devices.filter(
      (device) => device.id == selectedDevice?.id
    );
    user_type === "Manufacturer"
      ? (dispatch(setActiveDevice(completeDeviceInfo[0])),
        navigate(`/dashboard/${completeDeviceInfo[0]?.device_model?.name}`, {
          state: { selectedDevice },
        }))
      : (dispatch(setActiveDevice(selectedDevice)),
        navigate(`/dashboard/${selectedDevice?.name}`, {
          state: { selectedDevice },
        }));
  };

  return (
    <>
      {device_list?.length > 0 ? (
        <Grid {...styles.gridWatchlist}>
          {device_list?.map((deviceData, index) => {
            return (
              <GridItem
                colSpan={{ base: "2", md: "1", lg: "1" }}
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  // TODO: set Active Device Selected
                  onClickDevice(deviceData)
                }
              >
                <Card {...styles.watchlistCard}>
                  <CardHeader>
                    <Flex {...styles.headerFlex}>
                      <Heading {...styles.heading}>
                        {deviceData?.name?.length > 20
                          ? `${deviceData.name.slice(0, 15)}...`
                          : deviceData?.name}
                      </Heading>
                      <Spacer />
                      <MdOutlineWifi
                        color={
                          deviceData?.is_configured == "online"
                            ? "#30C268"
                            : "red"
                        }
                      />
                      <MdBluetoothConnected {...styles.greenIcon} />
                    </Flex>
                  </CardHeader>
                  <CardBody {...styles.watchlistCardBody}>
                    {deviceData?.data_points?.length > 0 ? (
                      deviceData?.data_points.map((data_point, index) => {
                        return (
                          <Flex {...styles.cardBodyFlex} key={index}>
                            <Heading
                              {...styles.pressureReading}
                              color={"green"}
                            >
                              {/* // color={
                    //   deviceData?.reading > deviceData?.thresholdStart &&
                    //   deviceData?.reading < deviceData?.thresholdEnd
                    //     ? "#30C268"
                    //     : "red"
                    // }> */}
                              {data_point?.maximum} {data_point?.unit?.unit}
                            </Heading>
                            <Text {...styles.pressureThreshold}>
                              {data_point?.unit?.type} - {data_point?.minimum} -{" "}
                              {data_point?.maximum} {data_point?.unit?.unit}
                            </Text>
                          </Flex>
                        );
                      })
                    ) : (
                      <Flex {...styles.cardBodyFlex} key={index}>
                        <Heading {...styles.pressureReading} color={"green"}>
                          {/* // color={
                //   deviceData?.reading > deviceData?.thresholdStart &&
                //   deviceData?.reading < deviceData?.thresholdEnd
                //     ? "#30C268"
                //     : "red"
                // }> */}
                          0 Pa
                        </Heading>
                        <Text {...styles.pressureThreshold}>None</Text>
                      </Flex>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            );
          })}
        </Grid>
      ) : (
        <Text
          {...styles.pressureThreshold}
          align={"center"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          fontSize="18"
        >
          No device data to show
        </Text>
      )}
    </>
  );
}
DeviceWatchlist.propTypes = {
  device_list: PropTypes.array.isRequired,
};

export default DeviceWatchlist;
