import { SearchIcon, TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import ProductDetails from "../components/ProductDetails";
import { setActiveDevice } from "../redux/reducers/controllersReducer";

const Product = () => {
  const activeProduct = useSelector((state) => state.controllers.activeProduct);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceData = useSelector((state) => state?.devices?.devices);
  const [sortConfig, setSortConfig] = useState({ key: 'serial_number', direction: 'ascending' });

  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
    graphBox: {
      p: 4,
      boxShadow: "md",
      bgColor: "white",
      borderRadius: 6,
    },
    topGrid: {
      templateColumns: "repeat(4, 1fr)",
      gap: { base: 1, lg: 6 },
      px: { base: 0, lg: 4 },
      pb: { base: 2, md: 6 },
      alignItems: "end",
    },

    headerText: {
      color: "#4A5568",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      letterSpacing: "0.05rem",
      fontWeight: 700,
      py: "0.25rem",
    },
    searchBar: {
      borderRadius: 6,
    },
    searchButton: {
      colorScheme: "facebook",
      width: "66%",
      size: { base: "xs", md: "sm" },
    },
    resetButton: {
      colorScheme: "facebook",
      variant: "outline",
      width: "30%",
      size: { base: "xs", md: "sm" },
    },
  };

  const handleInputChange = (value) => {
    setSearchParams(value);
  };

  const handleRowClick = (device) => {
    const activeDevice = deviceData.filter((dev) => dev?.id === device?.id);
    dispatch(setActiveDevice(device));
    navigate(
      `/products/${activeDevice[0]?.device_type?.name}/${activeProduct?.revision}`
    );
  };

  useEffect(() => {
    let filteredDeviceData = activeProduct?.device?.filter((device) => {
      if (searchParams) {
        return device?.serial_number?.includes(searchParams);
      } else {
        return device;
      }
    });
    setFilteredDevices(filteredDeviceData);
  }, [searchParams]);

  // Sorting handler
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedFilteredDevices = React.useMemo(() => {
    if (sortConfig.key) {
      return filteredDevices?.slice().sort((a, b) => {
        const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
        const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredDevices;
  }, [filteredDevices, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon = sortConfig.direction === 'ascending' ? (
      <TriangleUpIcon ml={1} color={iconColor} />
    ) : (
      <TriangleDownIcon ml={1} color={iconColor} />
    );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />
  };

  return (
    <Box>
      <Breadcrumbs />
      <Flex {...styles.flexContainer}>
        <ProductDetails />
        <Box {...styles.graphBox}>
          <Grid {...styles.topGrid}>
            <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
              <Box>
                <Text {...styles.headerText}>What are you looking for?</Text>{" "}
                <InputGroup size={"sm"}>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    {...styles.searchBar}
                    type="text"
                    placeholder={`Search by Serial Number`}
                    value={searchParams}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                </InputGroup>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
              <Flex>
                <Button
                  {...styles.resetButton}
                  onClick={() => setSearchParams("")}
                >
                  Reset
                </Button>
              </Flex>
            </GridItem>
          </Grid>

          <Box {...styles.tableContainer}>
            <TableContainer {...styles.tableContainer}>
              <Table
                size={{ base: "sm", md: "md" }}
                variant="striped"
                colorScheme="blackAlpha"
                {...styles.table}
              >
                <Thead>
                  <Tr>
                    <Th onClick={() => handleSort('offline')}>
                      Status {renderSortIcon('offline')}
                    </Th>
                    <Th onClick={() => handleSort('serial_number')}>
                      Serial Number {renderSortIcon('serial_number')} 
                    </Th>
                    <Th onClick={() => handleSort('date_produced')}>
                      Date Produced {renderSortIcon('date_produced')}
                    </Th>
                    {/* <Th onClick={() => handleSort('organization')}>
                      Managed By {renderSortIcon('organization')}
                    </Th> */}
                    {/* <Th onClick={() => handleSort('organization')}>
                      Manager Contact {renderSortIcon('organization')}
                    </Th> */}
                    <Th onClick={() => handleSort('date_tested')}>
                      Date Tested {renderSortIcon('date_tested')}
                    </Th>
                    <Th onClick={() => handleSort('tested_by')}>
                      Tested By {renderSortIcon('tested_by')}
                    </Th>
                    <Th onClick={() => handleSort('firmware')}>
                      Firmware {renderSortIcon('firmware')}
                    </Th>
                    <Th onClick={() => handleSort('assigned_to_customer_organization')}>
                      Customer {renderSortIcon('assigned_to_customer_organization')}
                    </Th>
                    <Th onClick={() => handleSort('where_installed')}>
                      Where Installed {renderSortIcon('where_installed')}
                    </Th>
                    {/* <Th>
                      Comments
                    </Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredDevices?.length ? (
                    sortedFilteredDevices?.map((device, index) => {
                      return (
                        <Tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRowClick(device)}
                        >
                          <Td {...styles.customerName}>{"Offline"}</Td>
                          <Td {...styles.customerName}>
                            {device?.serial_number}
                          </Td>
                          <Td {...styles.customerName}>
                            {moment(device?.date_produced).format("DD-MM-YYYY")}
                          </Td>
                          {/* <Td {...styles.customerName}>
                            {device?.organization
                              ? device?.organization?.first_name
                                ? device?.organization?.first_name +
                                  " " +
                                  (device?.organization?.last_name
                                    ? device?.organization?.last_name
                                    : "")
                                : ""
                              : ""}
                          </Td> */}
                          {/* <Td {...styles.customerName}>
                            {device?.organization?.phone_number
                              ? device?.organization?.phone_number
                              : "N/A"}
                          </Td> */}
                          <Td {...styles.customerName}>
                            {moment(device?.date_tested).format("DD-MM-YYYY")}
                          </Td>
                          <Td {...styles.customerName}>
                            {device?.tested_by
                              ? `${device?.tested_by?.first_name}
                          ${device?.tested_by?.last_name}`
                              : "N/A"}
                          </Td>
                          <Td {...styles.customerName}>
                            {device?.firmware?.name}
                          </Td>
                          <Td {...styles.customerName}>
                            {device?.assigned_to_customer_organization
                              ?.length == 0
                              ? "In Stock"
                              : device?.assigned_to_customer_organization?.name}
                          </Td>
                          <Td {...styles.customerName}>
                            {device?.where_installed
                              ? device?.where_installed.address
                              : "N/A"}
                          </Td>
                          {/* <Td {...styles.customerName}>{"N/A"}</Td> */}
                        </Tr>
                      );
                    })
                  ) : (
                    <Text align={"center"} p={4}>
                      No product found
                    </Text>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Product;
