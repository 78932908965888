import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import ControllerSearchFilters from "../components/controllersSearchFilters";
import Loader from "../components/loader";
import { assignBulkDeviceAPI, getDeviceAPI } from "../redux/helpers/deviceAPI";
import SuccessMessageModal from "../components/successMessageModal";
import {
  EmailIcon,
  PhoneIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { getAllProducts } from "../redux/helpers/controllerAPI";

function AssignDeviceToCustomerPopRevamp() {
  const { onClose } = useDisclosure();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { customer } = location.state || {};
  const devices = useSelector((state) =>
    state?.controllers?.devices?.filter(
      (device) => device?.assigned_to_seller_organization?.id == null
    )
  );

  const dispatch = useDispatch();
  const toast = useToast();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);

  const activeCustomerOrg = useSelector(
    (state) => state?.customer?.activeCustomerOrg
  );

  //================================================================================
  const { allProducts } = useSelector((state) => state.controllers);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "type",
    direction: "ascending",
  });
  const [inputValues, setInputValues] = useState({});
  const inputRefs = useRef({});
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!allProducts?.length) {
      setLoading(true);
      dispatch(getAllProducts()).then((res) => {
        setFilteredProducts(
          res?.payload?.data?.filter(
            (prod) =>
              Number(prod?.total_quantity) - Number(prod?.quantity_sold) > 0
          )
        );
        setLoading(false);
      });
    } else {
      setFilteredProducts(
        allProducts?.filter(
          (prod) =>
            Number(prod?.total_quantity) - Number(prod?.quantity_sold) > 0
        )
      );
    }
  }, []);

  // Sorting handler
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedProducts = React.useMemo(() => {
    if (sortConfig.key) {
      return filteredProducts.slice().sort((a, b) => {
        const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
        const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredProducts;
  }, [filteredProducts, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon =
      sortConfig.direction === "ascending" ? (
        <TriangleUpIcon ml={1} color={iconColor} />
      ) : (
        <TriangleDownIcon ml={1} color={iconColor} />
      );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />;
  };

  // Update input value state
  const handleQtyAssignInputChange = (modelRevisionId, value) => {
    setInputValues({
      ...inputValues,
      [modelRevisionId]: value,
    });
  };

  //================================================================================
  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
    formFlex: {
      alignItems: "center",
      gap: 2,
    },
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
    buttonFlex: {
      justifyContent: "end",
    },
    cancelButton: {
      mr: 5,
      size: "sm",
      width: "10rem",
    },
    submitButton: {
      size: "sm",
      colorScheme: "facebook",
      width: "10rem",
    },
    tableContainer: {
      overflowY: "auto",
      h: "46vh",
    },
    indecatorBox: {
      mb: -1,
      pr: 2,
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
    flexContainer2: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    const selectedProductsData = Object.entries(inputValues)
      ?.filter(([modelRevisionId, quantity]) => quantity > 0)
      ?.map(([modelRevisionId, quantity]) => {
        const product = sortedProducts?.find(
          (prod) => Number(prod?.model_revision_id) === Number(modelRevisionId)
        );
        return {
          modelRevisionId,
          quantity,
          type: product?.type?.name,
          model: product?.model?.name,
          revision: product?.revision,
          quantityInStock:
            Number(product?.total_quantity) - Number(product?.quantity_sold),
        };
      });

    // Check if any product's quantity exceeds its quantityInStock
    const outOfStockProduct = selectedProductsData?.find(
      (product) => Number(product?.quantity) > Number(product?.quantityInStock)
    );

    // Check if any product's quantity is in Negetive Number
    const negativeQuantityEntered = selectedProductsData?.find(
      (product) => Number(product?.quantity) < 0
    );

    if (negativeQuantityEntered) {
      toast({
        title: "Failed to assign product",
        description: `The quantity for ${outOfStockProduct?.model} is in negative value.`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else if (outOfStockProduct) {
      toast({
        title: "Failed to assign products",
        description: `The quantity for ${outOfStockProduct?.model} exceeds the available stock of ${outOfStockProduct?.quantityInStock}.`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else {
      setSelectedProducts(selectedProductsData);
      setIsOpen(true);
    }
  };

  const assignDevice = () => {
    setLoading(true);
    try {
      const promises = Object.entries(inputValues)?.map(
        async ([modelRevisionId, quantity]) => {
          const payload = {
            revision_id: modelRevisionId,
            assigned_to_id: activeCustomerOrg?.id,
            quantity: quantity,
          };
          return dispatch(assignBulkDeviceAPI(payload));
        }
      );

      Promise.all(promises)
        .then(async (results) => {
          const successResults = results.filter(
            (result) => result?.payload?.success
          );
          if (successResults.length > 0) {
            await dispatch(getDashboardDataAPI());
            dispatch(getDeviceAPI()).then((response) => {
              dispatch(getAllProducts()).then((res) => {
                setFilteredProducts(res?.payload?.data);
              });
              toast({
                title: "Success",
                position: "top-right",
                description: `${successResults.length} devices assigned successfully!`,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
              setLoading(false);
              navigate(-1);
              onCloseModal();
              onClose();
            });
          } else {
            setLoading(false);
            onCloseModal();
            toast({
              title: "Error while assigning",
              description: "Failed to assign products",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Box>
        <Loader loading={loading} />
        <Flex>
          <Box>
            <Breadcrumbs />
          </Box>
        </Flex>
        <Flex {...styles.flexContainer}>
          <Card size={{ base: "sm", md: "md" }}>
            <CardBody>
              <Flex {...styles.flexContainer2}>
                <Flex
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Avatar {...styles.avatar} name={activeCustomerOrg?.name} />
                  <Box ml={4}>
                    <Flex gap={2}>
                      <Heading {...styles.deviceHeader}>
                        {activeCustomerOrg?.name}
                      </Heading>
                    </Flex>
                    <Flex {...styles.subheaderItems} mt={1}>
                      {activeCustomerOrg?.first_name}{" "}
                      {activeCustomerOrg?.last_name}
                    </Flex>

                    <Flex {...styles.subheaderItems} mt={1}>
                      <Flex {...styles.subheaderItems}>
                        <PhoneIcon />
                        <Text {...styles.subheaderText}>
                          {activeCustomerOrg?.phone_number}{" "}
                        </Text>
                      </Flex>
                      <Flex
                        {...styles.subheaderItems}
                        style={{ marginLeft: 16 }}
                      >
                        <EmailIcon />
                        <Text {...styles.subheaderText}>
                          {activeCustomerOrg?.email}{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <Flex flexDirection={"column"}>
            <Box {...styles.topBox}>
              {filteredProducts?.length > 0 ? (
                <TableContainer {...styles.tableContainer}>
                  <Table
                    size={{ base: "sm", md: "md" }}
                    variant="striped"
                    colorScheme="blackAlpha"
                  >
                    <Thead>
                      <Tr>
                        <Th onClick={() => handleSort("type")}>
                          Product {renderSortIcon("type")}
                        </Th>
                        <Th onClick={() => handleSort("model")}>
                          Model {renderSortIcon("model")}
                        </Th>
                        <Th onClick={() => handleSort("revision")}>
                          Revision {renderSortIcon("revision")}
                        </Th>
                        <Th onClick={() => handleSort("total_quantity")}>
                          Qty In-stock {renderSortIcon("total_quantity")}
                        </Th>
                        <Th>Qty To-assign</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortedProducts?.map((product, index) => {
                        const availableQuantity =
                          Number(product?.total_quantity) -
                          Number(product?.quantity_sold);

                        return (
                          <Tr key={index}>
                            <Td {...styles.customerName}>
                              {product?.type?.name}
                            </Td>
                            <Td {...styles.customerName}>
                              {product?.model?.name}
                            </Td>
                            <Td {...styles.customerName}>
                              {product?.revision}
                            </Td>
                            <Td {...styles.customerName}>
                              {availableQuantity}
                            </Td>
                            <Td>
                              <Flex alignItems="center">
                                <NumberInput
                                  min={0}
                                  max={availableQuantity}
                                  clampValueOnBlur={false}
                                  defaultValue={0}
                                  onChange={(value) => {
                                    if (value < 0) {
                                      toast({
                                        title:
                                          "Quantity should be greater than 0",
                                        description: `The quantity for ${product?.model?.name} is in negative value.`,
                                        status: "error",
                                        duration: 6000,
                                        isClosable: true,
                                      });
                                    } else {
                                      handleQtyAssignInputChange(
                                        product?.model_revision_id,
                                        value
                                      );
                                    }
                                  }}
                                  ref={(ref) =>
                                    (inputRefs.current[product?.id] = ref)
                                  }
                                >
                                  <NumberInputField placeholder="Assign Qty" />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Text align={"center"} p={4}>
                  No product found
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>

        {filteredProducts?.length > 0 ? (
          <Flex mt={5} justifyContent={"right"} mr={4}>
            {selectedProducts?.length >= 0 ? (
              <>
                <Button
                  colorScheme="red"
                  variant="outline"
                  size="sm"
                  mr={5}
                  width="10rem"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  {...styles.submitButton}
                >
                  Assign
                </Button>
              </>
            ) : null}
          </Flex>
        ) : (
          <span> </span>
        )}

        <Modal isOpen={isOpen} onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent maxW="800px">
            <ModalHeader>Assign Products</ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH="400px" overflowY="auto">
              {selectedProducts?.length > 0 ? (
                <Box>
                  <Text>
                    Are you sure you want to assign the following Products?
                  </Text>
                  <Table variant="simple" mt={4}>
                    <Thead>
                      <Tr>
                        <Th>Product</Th>
                        <Th>Model</Th>
                        <Th>Revision</Th>
                        <Th>Quantity Assigned</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedProducts?.map((product, index) => (
                        <Tr key={index}>
                          <Td>{product?.type}</Td>
                          <Td>{product?.model}</Td>
                          <Td>{product?.revision}</Td>
                          <Td>{product?.quantity}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ) : (
                <Text>No devices selected.</Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                mr={2}
                size={"sm"}
                colorScheme="red"
                variant="outline"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <Button
                size={"sm"}
                colorScheme="facebook"
                onClick={assignDevice}
                isDisabled={selectedProducts.length === 0 ? true : false}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`Devices has been assigned successfully!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
}

AssignDeviceToCustomerPopRevamp.prototypes = {
  deviceData: PropTypes.object,
};

export default AssignDeviceToCustomerPopRevamp;
