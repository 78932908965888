import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  deviceDataAttributeHeadText,
  deviceDataAttributeHelperText,
} from "../utills/labels";
import ModelFooterButtons from "./modelFooterButtons";

const EditDeviceAttributesModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
  device,
  removeDeviceDataPoint
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const types = useSelector((state) => state?.controllers?.types);
  const units = useSelector((state) => state?.controllers?.units);
  const user_type = useSelector((state) => state?.auth?.user_type);

  // const [isTypeChanged, setIsTypeChanged] = useState();
  // useEffect(() => {
  //   setIsTypeChanged(device?.device_type?.id);
  // }, [device]);
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    device_type: Yup.string().required("Type is required"),
    device_model_name: Yup.string()
      // .required("Model name is required")
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Model name can only contain Letters, Numbers, Hyphen & Spaces"
      ),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          // .required("Monitoring Value is required")
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            nameRegex,
            "Monitoring Value can only contain Letters, Numbers & Spaces"
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number()
          .required("Maximum value is required")
          .test(
            "max-greater-than-min",
            "Max must be greater than Min",
            function (value) {
              const { min } = this.parent;
              return value > min;
            }
          ),
        unit_id: Yup.string().required("Unit is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    //er
    const data =
      values?.device_attributes.length === device?.data_points?.length
        ? {
            device_id: device?.id,
            name: values.device_model_name || "",
            data_points: values?.device_attributes
              .map((attribute) => {
                const dataPoint = device?.data_points?.find(
                  (dp) => dp?.id === attribute?.data_point_id
                );
                const updatedDataPoint = {};

                if (
                  dataPoint?.unit?.type !== attribute?.monitoring_value ||
                  dataPoint?.unit?.id !== attribute?.unit_id ||
                  dataPoint?.minimum !== attribute?.min ||
                  dataPoint?.maximum !== attribute?.max ||
                  dataPoint?.is_monitored !== attribute?.is_monitored
                ) {
                  updatedDataPoint.data_point_id = attribute?.data_point_id;
                }
                updatedDataPoint.monitoring_value = attribute?.monitoring_value;
                updatedDataPoint.unit_id = attribute?.unit_id;
                updatedDataPoint.min = attribute?.min.toString();
                updatedDataPoint.max = attribute?.max.toString();
                updatedDataPoint.is_monitored = attribute?.is_monitored;
                
                updatedDataPoint.data_point_id = attribute?.data_point_id;

                return updatedDataPoint;
              })
              .filter(Boolean),
          }
        : {
            device_id: device?.id,
            name: values.device_model_name || "",
            data_points: values?.device_attributes
              .map((attribute) => {
                const dataPoint = device?.data_points?.find(
                  (dp) => dp?.id === attribute?.data_point_id
                );

                if (dataPoint?.id === attribute?.data_point_id) {
                  const updatedDataPoint = {};
                  if (
                    dataPoint?.unit?.type !== attribute?.monitoring_value ||
                    dataPoint?.unit?.id !== attribute?.unit_id ||
                    dataPoint?.minimum !== attribute?.min ||
                    dataPoint?.maximum !== attribute?.max
                  ) {
                    updatedDataPoint.data_point_id = attribute?.data_point_id;
                    updatedDataPoint.monitoring_value =
                      attribute?.monitoring_value;
                    updatedDataPoint.unit_id = attribute?.unit_id;
                    updatedDataPoint.min = attribute?.min.toString();
                    updatedDataPoint.max = attribute?.max.toString();
                    updatedDataPoint.is_monitored = attribute?.is_monitored;
                  }

                  if (_.isEmpty(updatedDataPoint) === true) {
                    return null
                  } else {
                    return updatedDataPoint
                  }

                  // return updatedDataPoint;

                } else {
                  const newDataPoint = {};
                  newDataPoint.monitoring_value = attribute?.monitoring_value;
                  newDataPoint.unit_id = attribute?.unit_id;
                  newDataPoint.min = attribute?.min.toString();
                  newDataPoint.max = attribute?.max.toString();
                  newDataPoint.is_monitored = attribute?.is_monitored
                    ? attribute?.is_monitored
                    : false;
                  return newDataPoint;
                }
              })
              .filter(Boolean),
          };
    
    // we have to avoid sending empty object out from here. 
    if (data.data_points.length > 0) {
      onSave(data);
    }
    
  };

  const styles = {
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  console.log("Modify device", device);

  return (
    <>
      {/* <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Model" /> */}
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modify Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                device_type: device?.device_type?.id || "",
                device_model_name: device?.name || "",
                device_attributes:
                  (device?.data_points &&
                    device.data_points.map((dataPoint) => ({
                      monitoring_value: dataPoint?.unit?.type || "",
                      unit_id: dataPoint?.unit?.id || "",
                      min: dataPoint?.minimum || "",
                      max: dataPoint?.maximum || "",
                      data_point_id: dataPoint?.id || "",
                      is_monitored: dataPoint?.is_monitored || false,
                    }))) ||
                  [],
              }}
              validateOnChange
              validateOnBlur
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldTouched,
              }) => {
                useEffect(() => {
                  if (Object.keys(errors).length)
                    if (values.device_attributes.length === 1) {
                      setFieldTouched(
                        `device_attributes.${0}.monitoring_value`,
                        true
                      );
                      setFieldTouched(`device_attributes.${0}.unit_id`, true);
                      setFieldTouched(`device_attributes.${0}.min`, true);
                      setFieldTouched(`device_attributes.${0}.max`, true);
                    } else {
                      values.device_attributes.forEach((_, index) => {
                        setFieldTouched(
                          `device_attributes.${index}.monitoring_value`,
                          true
                        );
                        setFieldTouched(
                          `device_attributes.${index}.unit_id`,
                          true
                        );
                        setFieldTouched(`device_attributes.${index}.min`, true);
                        setFieldTouched(`device_attributes.${index}.max`, true);
                       
                      });
                    }
                }, [values.device_attributes, errors]);
                return (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors?.device_type && touched?.device_type}
                    >
                      <FormLabel>Product</FormLabel>
                      <Text fontSize={"lg"} mb={"4"} mt={2}>
                        {device?.device_type?.name}
                      </Text>
                      {/* <Select
                        isDisabled={true}
                        {...styles.input}
                        id="device_type"
                        ref={initialRef}
                        name="device_type"
                        placeholder="Select Type"
                        value={values.device_type}
                        onChange={(e) => {
                          handleChange(e);
                          // setIsTypeChanged(e.target.value);
                        }}
                      >
                        {types?.map((val) => (
                          <option key={uuidv4()} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                      </Select> */}
                      <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors?.device_model_name && touched?.device_model_name
                      }
                    >
                      <FormLabel>Model</FormLabel>
                      <Text fontSize={"lg"} mb={"4"} mt={2}>
                        {device?.device_model?.name}
                      </Text>
                      {/* <Input
                        isDisabled={true}
                        // isDisabled={device?.device_type?.id === isTypeChanged}
                        {...styles.input}
                        placeholder="Ex: RX-100"
                        name="device_model_name"
                        defaultValue={device?.device_model?.name}
                        // value={values.device_model_name}
                        onChange={handleChange}
                      /> */}
                      <FormErrorMessage>
                        {errors.device_model_name}
                      </FormErrorMessage>
                    </FormControl>
                    <Text style={{ marginTop: 10 }}>
                      {deviceDataAttributeHeadText}
                    </Text>
                    <Text
                      style={{
                        marginTop: 6,
                        fontSize: 12,
                        fontStyle: "normal",
                        fontWeight: 300,
                      }}
                    >
                      {deviceDataAttributeHelperText}
                    </Text>

                    <FieldArray
                      name="device_attributes"
                      style={{
                        marginBottom: 22,
                        padding: 4,
                        marginTop: 4,
                        border: "1px dotted lightgrey",
                        borderRadius: 8,
                      }}
                    >
                      {(arrayHelpers) => (
                        <>
                          {values?.device_attributes.map((val, index) => {
                            const availableUnits =
                              units?.filter(
                                (item) => item?.name === val?.monitoring_value
                              )?.[0]?.measurements || [];

                            return (
                              <Box
                                key={index}
                                style={{
                                  marginBottom: 22,
                                  padding: 4,
                                  marginTop: 6,
                                  border: "1px dotted lightgrey",
                                  borderRadius: 8,
                                }}
                              >
                                {user_type === "Manufacturer" ? (
                                  <></>
                                ) : (
                                  <Flex justifyContent={"end"}>
                                    <IconButton
                                      size={"xs"}
                                      fontSize={"10px"}
                                      color={"red"}
                                      icon={<CloseIcon />}
                                      onClick={() => {
                                        // drop the item from UI
                                        arrayHelpers.remove(index);

                                        // drop the item from API
                                        const payload = {
                                          data_points_id:
                                            values?.device_attributes[index]
                                              ?.data_point_id,
                                          device_id: device?.id,
                                        };
                                        removeDeviceDataPoint(payload);
                                      }}
                                    />
                                  </Flex>
                                )}

                                <FormControl
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>
                                    {`Monitoring Value ${index + 1}`}{" "}
                                    {/* {
                                      values?.device_attributes[index]
                                        .data_point_id
                                    }
                                    {console.log("values", values)} */}
                                  </FormLabel>
                                  <Select
                                    isDisabled={
                                      user_type === "Manufacturer"
                                        ? true
                                        : false
                                    }
                                    {...styles.input}
                                    placeholder="Select Monitoring Value"
                                    name={`device_attributes.${index}.monitoring_value`}
                                    value={
                                      values?.device_attributes[index]
                                        ?.monitoring_value || ""
                                    }
                                    onChange={handleChange}
                                  >
                                    {[
                                      ...new Set(
                                        units?.map((unit) => unit?.name)
                                      ),
                                    ]?.map((val) => (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    ))}
                                  </Select>
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      errors.device_attributes[index]
                                        .monitoring_value}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  pt={2}
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>Unit</FormLabel>
                                  <Select
                                    isDisabled={
                                      user_type === "Manufacturer"
                                        ? true
                                        : false
                                    }
                                    {...styles.input}
                                    placeholder="Select Unit"
                                    name={`device_attributes.${index}.unit_id`}
                                    value={
                                      values?.device_attributes[index]
                                        ?.unit_id || ""
                                    }
                                    onChange={handleChange}
                                  >
                                    {availableUnits?.map((unit) => (
                                      <option key={unit?.id} value={unit?.id}>
                                        {unit?.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      errors.device_attributes[index].unit_id}
                                  </FormErrorMessage>
                                </FormControl>
                                <Center pt={2}>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Minimum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      // disabled
                                      type="number"
                                      placeholder="Minimum"
                                      name={`device_attributes.${index}.min`}
                                      // value={values.device_attributes[index].min}
                                      defaultValue={
                                        device?.data_points[index]?.minimum
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].min}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <Box px={2}></Box>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Maximum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      // disabled
                                      type="number"
                                      placeholder="Maximum"
                                      name={`device_attributes.${index}.max`}
                                      // value={values.device_attributes[index].max}
                                      defaultValue={
                                        device?.data_points[index]?.maximum
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].max}
                                    </FormErrorMessage>
                                  </FormControl>
                                </Center>
                                <FormControl
                                  pt={2}
                                  isInvalid={
                                    errors.is_monitored && touched.is_monitored
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>
                                    Monitoring Status{" "}
                                  </FormLabel>
                                  <Checkbox
                                    size="sm"
                                    name={`device_attributes.${index}.is_monitored`}
                                    colorScheme="green"
                                    isChecked={
                                      values?.device_attributes[index]
                                        ?.is_monitored || false
                                    }
                                    onChange={handleChange}
                                  >
                                    Monitor this Unit
                                  </Checkbox>
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      errors.device_attributes[index]
                                        .is_monitored}
                                  </FormErrorMessage>
                                </FormControl>
                              </Box>
                            );
                          })}
                          {user_type != "Manufacturer" && (
                            <Button
                              // isDisabled={true}
                              onClick={() =>
                                arrayHelpers.push({
                                  monitoring_value: "",
                                  min: "",
                                  max: "",
                                  unit_id: "",
                                  data_point_id: null,
                                })
                              }
                              size={"xs"}
                              colorScheme="teal"
                            >
                              Add Attribute
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>

                    <ModelFooterButtons
                      onClose={onClose}
                      onSave={handleSubmit}
                    />
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditDeviceAttributesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  removeDeviceDataPoint: PropTypes.func,
};

export default EditDeviceAttributesModal;
