import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { FieldArray, Formik } from "formik";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  addModelAPI,
  addUnitsAPI,
  getModelsAPI,
  getTypesAPI,
  getUnitsAPI,
} from "../redux/helpers/controllerAPI";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

function UnitsTabs() {
  const location = useLocation();
  const toast = useToast();
  const { customer } = location.state || {};
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    formItem: {
      m: 4,
    },
    input: {
        w: "40vw",
      size: "sm",
      borderRadius: 6,
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cancelButton: {
      variant: "outline",
      size: "sm",
      mr: 5,
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
      justifyContent: "end",
    },
  };
  // const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const initialRef = useRef(null);
  const units = useSelector((state) => state?.controllers?.units);
  const [loading, setLoading] = useState(false);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    unit_name: Yup.string()
      .required("Measurement name is required")
      .matches(
        /^[A-Za-z0-9\- ]+$/,
        "Model name can only contain Letters, Numbers, Hyphen & Spaces"
      ),
    unit_measurements: Yup.array().of(
      Yup.object().shape({
        measurement_name: Yup.string()
          .required("Unit Name is required")
          .matches(
            /^[A-Za-z0-9 ]+$/,
            "Monitoring Value can only contain Letters, Numbers & Spaces"
          ),
          measurement_abbreviation: Yup.string().required("Abbreviation is required"),
      })
    ),
  });

  const onSave = (payload) => {
    console.log(payload,'PAYLOAD')
    setLoading(true);
    try {
      let addModelres = dispatch(addUnitsAPI(payload));
      addModelres.then((data) => {
        const { success, message } = data.payload;
        if (success) {
          dispatch(getUnitsAPI())
            .then(() => {
              toast({
                title: "Success",
                position: "top-right",
                description: message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
              setLoading(false);
              handleBackClick();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              toast({
                title: "Error",
                description: "Error in fetching models",
                status: "error",
                duration: 6000,
                isClosable: true,
              });
            });
        } else {
          setLoading(false);
          toast({
            title: "Error",
            description: message || data?.payload?.error,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleSubmit = (values) => {
    onSave({
      unit: values.unit_name,
      measurement: values?.unit_measurements.map((attribute) => ({
        ...attribute,
        name: attribute?.measurement_name,
        abbreviation: attribute?.measurement_abbreviation,
      })),
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBackClick = () => {
    navigate("/product-configuration", { state: { tourindex: 1 } });
  };

  const handleReset = () => {
    initialRef.current.reset();
  };

  console.log(customer);

  return (
    <Box>
      <Loader loading={loading} />
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Add New Unit and Measurements
        </Text>
      </Flex>
      <Card size={{ base: "sm", md: "md"}}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Formik
              initialValues={{
                unit_name: "",
                unit_measurements: [{}],
              }}
              validationSchema={validationSchema}
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldTouched,
                resetForm,
              }) => {
                useEffect(() => {
                  if (Object.keys(errors).length)
                    if (values.unit_measurements.length === 1) {
                      setFieldTouched(
                        `unit_measurements.${0}.measurement_name`,
                        true
                      );
                      setFieldTouched(`unit_measurements.${0}.measurement_abbreviation`, true);
                    } else {
                      values.unit_measurements.forEach((_, index) => {
                        setFieldTouched(
                          `unit_measurements.${index}.measurement_name`,
                          true
                        );
                        setFieldTouched(`unit_measurements.${index}.measurement_abbreviation`, true);
                      });
                    }
                }, [values.unit_measurements, errors]);
                return (
                  <form ref={initialRef} onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors?.unit_name && touched?.unit_name}
                    >
                      <FormLabel marginTop={3}>Measurement</FormLabel>
                      <Input
                        {...styles.input}
                        placeholder="Ex: Temperature"
                        name="unit_name"
                        value={values.unit_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{errors.unit_name}</FormErrorMessage>
                    </FormControl>
                    <Text style={{ marginTop: 10 }}>Units</Text>

                    <FieldArray
                      name="unit_measurements"
                      style={{
                        marginBottom: 22,
                        padding: 4,
                        marginTop: 4,
                        border: "1px dotted lightgrey",
                        borderRadius: 8,
                      }}
                    >
                      {(arrayHelpers) => (
                        <>
                          {values.unit_measurements.map((_, index) => {
                            return (
                              <Box
                                key={index}
                                style={{
                                  marginBottom: 22,
                                  padding: 10,
                                  marginTop: 6,
                                  border: "1px dotted lightgrey",
                                  borderRadius: 8,
                                }}
                              >
                                <Flex justifyContent={"end"}>
                                  <IconButton
                                    zIndex={1}
                                    size={"xs"}
                                    fontSize={"10px"}
                                    color={"red"}
                                    icon={<CloseIcon />}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                </Flex>

                                <FormControl
                                  marginTop={-4}
                                  isInvalid={
                                    errors.unit_measurements &&
                                    errors.unit_measurements[index]
                                      ?.measurement_name &&
                                    touched.unit_measurements &&
                                    touched.unit_measurements[index]
                                      ?.measurement_name
                                  }
                                >
                                  <FormLabel>
                                    Unit {index + 1}
                                  </FormLabel>
                                  <Input
                                    {...styles.input}
                                    placeholder="Ex: Kelvin"
                                    name={`unit_measurements.${index}.measurement_name`}
                                    value={
                                      values.unit_measurements[index]
                                        ?.measurement_name || ""
                                    }
                                    onChange={handleChange}
                                  ></Input>
                                  <FormErrorMessage>
                                    {errors.unit_measurements &&
                                      errors.unit_measurements[index]
                                        ?.measurement_name}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    marginTop={4}
                                  isInvalid={
                                    errors.unit_measurements &&
                                    errors.unit_measurements[index]?.measurement_abbreviation &&
                                    touched.unit_measurements &&
                                    touched.unit_measurements[index]?.measurement_abbreviation
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>
                                    Abbreviation
                                  </FormLabel>
                                  <Input
                                    {...styles.input}
                                    placeholder="Ex: K"
                                    name={`unit_measurements.${index}.measurement_abbreviation`}
                                    value={
                                      values.unit_measurements[index]?.measurement_abbreviation || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <FormErrorMessage>
                                    {errors.unit_measurements &&
                                      errors.unit_measurements[index]?.measurement_abbreviation}
                                  </FormErrorMessage>
                                </FormControl>
                              </Box>
                            );
                          })}
                          <Button
                            type="button"
                            style={{ marginBottom: 18 }}
                            onClick={() =>
                              arrayHelpers.push({
                                measurement_name: "",
                                measurement_abbreviation: "",
                              })
                            }
                            size={"xs"}
                            colorScheme="teal"
                          >
                            Add Attribute
                          </Button>
                        </>
                      )}
                    </FieldArray>
                    <Flex mt={4}>
                      <Button
                        {...styles.cancelButton}
                        onClick={handleBackClick}
                        colorScheme="red"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          resetForm();
                          handleReset();
                        }}
                        {...styles.cancelButton}
                      >
                        Reset
                      </Button>
                      <Button
                        {...styles.submitButton}
                        type="submit"
                        colorScheme="facebook"
                      >
                        Submit
                      </Button>
                    </Flex>
                  </form>
                );
              }}
            </Formik>
          </Flex>
        </CardBody>
      </Card>
      {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          onClose={() => setisSuccessModalOpen(false)}
          message={successMessage}
        />
      )} */}
    </Box>
  );
}

export default UnitsTabs;
