import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addCustomerAPI = createAsyncThunk(
  "addCustomer",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/manufacturer/sellers`,
        {
          first_name: payload.org_contact_person_first_name,
          last_name: payload.org_contact_person_last_name,
          role_id: payload.role_id,
          email: payload.org_contact_person_email,
          phone_number: payload.org_contact_person_phone_number,
          organization_name: payload.organization_name,
          org_contact_person_first_name: payload.org_contact_person_first_name,
          org_contact_person_last_name: payload.org_contact_person_last_name,
          org_contact_person_email: payload.org_contact_person_email,
          org_contact_person_phone_number:
            payload.org_contact_person_phone_number,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const editCustomerAPI = createAsyncThunk(
  "editCustomer",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      const duplicatePayload = { ...payload };

      delete duplicatePayload.member_id;

      let response = await API.put(
        `/manufacturer/seller/${payload?.member_id}`,
        duplicatePayload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getCustomerAPI = createAsyncThunk(
  "getCustomer",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/manufacturer/sellers`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const deleteCustomerAPI = createAsyncThunk(
  "deleteCustomerAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/manufacturer/seller/${payload.seller_id}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const addOrganizationAPI = createAsyncThunk(
  "addOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/manufacturer/sellers/organization`,
        {
          first_name: payload?.org_contact_person_first_name,
          last_name: payload?.org_contact_person_last_name,
          role_id: payload?.role_id,
          email: payload?.org_contact_person_email,
          phone_number: payload?.org_contact_person_phone_number,
          organization_name: payload?.organization_name,
          org_contact_person_first_name: payload?.org_contact_person_first_name,
          org_contact_person_last_name: payload?.org_contact_person_last_name,
          org_contact_person_email: payload?.org_contact_person_email,
          org_contact_person_phone_number:
            payload?.org_contact_person_phone_number,
          full_address: payload?.organization_address,
          postalcode: payload?.postal_code,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getOrganizationsAPI = createAsyncThunk(
  "getOrganizations",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(
        `/manufacturer/sellers/organization`,
        config
      );
      console.log(response, "organizations?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getSellerOrganizationsAPI = createAsyncThunk(
  "getSellerOrganizationsAPI",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(
        `/seller/customers/organization`,
        config
      );
      console.log(response, "organizations?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getCustomerTeamAPI = createAsyncThunk(
  "getCustomerTeam",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(
        // `/manufacturer/seller/${getState().customer?.activeSellerOrgId}/team`,
        `/manufacturer/seller/team/${getState().customer?.activeSellerOrgId}`,
        config
      );
      console.log(response, "getCustomerTeamAPI?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const addCustomerTeamAPI = createAsyncThunk(
  "addCustomerTeam",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.post(
        `/manufacturer/seller/team/${getState().customer?.activeSellerOrgId}`,
        {
          first_name: payload?.first_name,
          last_name: payload?.last_name,
          role_id: payload?.privilege,
          email: payload?.email,
          phone_number: payload?.phone_number ,
        },
        config
      );
      console.log(response, "addCustomerTeamAPI?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const editOrganizationAPI = createAsyncThunk(
  "editOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      const duplicatePayload = { ...payload };

      delete duplicatePayload.member_id;

      let response = await API.put(
        `/manufacturer/sellers/organization/${payload.organization_id}`,
        duplicatePayload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const deleteOrganizationAPI = createAsyncThunk(
  "deleteOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/manufacturer/sellers/organization/${payload?.organization_id}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);
export const suspendOrganizationAPI = createAsyncThunk(
  "suspendOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const headers = {
        Authorization: getState().auth?.token,
        "Content-Type": "application/json",
      };
      const data = {
        is_suspended: payload.is_suspended,
        suspended_reason: "Suspended because of reason",
      };
      const response = await API.put(
        `/manufacturer/suspend/sellers/organization/${payload?.organization_id}`,
        data,
        { headers }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const approveOrganizationAPI = createAsyncThunk(
  "approveOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          Authorization: getState()?.auth?.token,
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await API.put(
        `/manufacturer/approve/customer-organization/${payload?.organization_id}`,
        payload,
        config
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);