import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";
import { FieldArray, Formik, Field } from "formik";
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import ModelFooterButtons from "./modelFooterButtons";
import Loader from "./loader";
import {
  deleteModelsDataPointsAPI,
  getModelsAPI,
} from "../redux/helpers/controllerAPI";
import DeleteDeviceModal from "./deleteDeviceModal";
import { addAttributeButtontext, deviceDataAttributeHeadText, deviceDataAttributeHelperText } from "../utills/labels";

const EditDeviceModelModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
  device,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleDeviceModel, setSingleDeviceModel] = useState("");
  const types = useSelector((state) => state?.controllers?.types);
  const units = useSelector((state) => state?.controllers?.units);
  const deletionType = "model-data-point";
  const firmwareDataList = useSelector((state) => state?.firmware?.firmwareData)?.filter((firmwareVal) => firmwareVal?.device_model?.id == device?.id)[0];

  const [fileName, setFileName] = useState("");

  const dispatch = useDispatch();

  const toast = useToast();

  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const versionRegex = /^[a-zA-Z0-9-.\s]+$/;
  const validationSchema = Yup.object().shape({
    device_type: Yup.string().required("Type is required"),
    device_model_name: Yup.string()
      // .required("Model name is required")
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Model name can only contain Letters, Numbers, Hyphen & Spaces"
      ),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          // .required("Monitoring Value is required")
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            nameRegex,
            "Monitoring Value can only contain Letters, Numbers & Spaces"
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number()
          .required("Maximum value is required")
          .test(
            "max-greater-than-min",
            "Max must be greater than Min",
            function (value) {
              const { min } = this.parent;
              return value > min;
            }
          ),
        unit_id: Yup.string().required("Unit is required"),
      })
    ),
    name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        versionRegex,
        "Firmware name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
    ),
    code_name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        versionRegex,
        "Code name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
    ),
    version_number: Yup.string()
      // .max(6, "Version number must be at most 6 characters.")
      .matches(
        versionRegex,
        "Version number can only contain Numbers, Full Stop & Hyphen"
    ),
  });

  const handleDeleteModalOpen = (val) => {
    setIsDeleteModalOpen(true);
    val = { ...val, model_id: device?.id };

    setSingleDeviceModel(val);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    payload = { ...payload };
    if (
      payload?.data_point_id === undefined ||
      payload?.data_point_id === "undefined"
    ) {
      setIsModalOpen(false);
      toast({
        title: "Success",
        position: "top-right",
        description: "Deleted Successfully",
        status: "success",
        duration: 6000,
        isClosable: true,
      });
      handleCloseDeleteModal();
      setLoading(false);
    } else {
      let deleteDataPointsRes = dispatch(deleteModelsDataPointsAPI(payload));
      deleteDataPointsRes
        .then((result) => {
          if (result?.payload?.success) {
            dispatch(getModelsAPI()).then(() => {
              setIsModalOpen(false);
              toast({
                title: "Success",
                position: "top-right",
                description: result?.payload?.message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
              handleCloseDeleteModal();
              setLoading(false);
            });
          } else {
            setLoading(false);
            dispatch(getModelsAPI());
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleSubmit = (values) => {
    const data = {
      model_id: device?.id,
      // type_id: parseInt(values.device_type),
      name:
        values.device_model_name === device?.name
          ? ""
          : values.device_model_name,
      data_points: values.device_attributes.map((attribute) => ({
        ...attribute,
        data_point_id: parseInt(attribute?.data_point_id),
      })),
    };

    const payload = Object.fromEntries(
      Object.entries(data)?.filter(([, value]) => value !== "")
    );

    const firmwarePayload = {
      firmware_id: firmwareDataList?.id ? firmwareDataList?.id : "",
      name: firmwareDataList?.name == values?.name ? "" : values?.name,
      model_id: firmwareDataList?.id ? "": device?.id,
      code_name:firmwareDataList?.code_name == values?.code_name ? "": values?.code_name,
      is_prod: firmwareDataList?.is_prod ==values?.is_prod?"": values?.is_prod === true ? 1 : 0,
      version_number:firmwareDataList?.version_number == values?.version_number? "": values?.version_number,
    };

    if (values?.file) {
      firmwarePayload.file = values?.file;
    }

    const firmwarePayloadData = Object.fromEntries(
      Object.entries(firmwarePayload)?.filter(([, value]) => value !== "")
    );

    console.log(firmwarePayloadData,'EDIT VALUESSSSS')
    onSave(payload, firmwarePayloadData);
  };

  const styles = {
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                device_type: device?.device_type?.id || "",
                device_model_name: device?.name || "",
                device_attributes:
                  (device?.model_data_points &&
                    device.model_data_points.map((dataPoint) => ({
                      monitoring_value: dataPoint?.data_point?.type || "",
                      unit_id: dataPoint?.data_point?.id || "",
                      min: dataPoint?.minimum || "",
                      max: dataPoint?.maximum || "",
                      data_point_id: dataPoint?.id || "",
                    }))) ||
                  [],
                
                name: firmwareDataList?.name || "",
                code_name: firmwareDataList?.code_name|| "",
                version_number: firmwareDataList?.version_number || "",
                file: "",
                is_prod: false,
              }}
              validationSchema={validationSchema}
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (Object.keys(errors).length)
                    if (values.device_attributes.length === 1) {
                      setFieldTouched(
                        `device_attributes.${0}.monitoring_value`,
                        true
                      );
                      setFieldTouched(`device_attributes.${0}.unit_id`, true);
                      setFieldTouched(`device_attributes.${0}.min`, true);
                      setFieldTouched(`device_attributes.${0}.max`, true);
                    } else {
                      values.device_attributes.forEach((_, index) => {
                        setFieldTouched(
                          `device_attributes.${index}.monitoring_value`,
                          true
                        );
                        setFieldTouched(
                          `device_attributes.${index}.unit_id`,
                          true
                        );
                        setFieldTouched(`device_attributes.${index}.min`, true);
                        setFieldTouched(`device_attributes.${index}.max`, true);
                      });
                    }
                }, [values.device_attributes, errors]);
                return (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors?.device_type && touched?.device_type}
                    >
                      <FormLabel style={{fontWeight:"bold"}}>Product</FormLabel>
                      <Text style={{marginBottom: 10, fontWeight:"bold"}}>{device?.device_type?.name}</Text>
                      {/* <Select
                        isDisabled={true}
                        {...styles.input}
                        id="device_type"
                        ref={initialRef}
                        name="device_type"
                        placeholder="Select Type"
                        value={values?.device_type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {types?.map((val) => (
                          <option key={uuidv4()} value={val?.id}>
                            {val?.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.device_type}</FormErrorMessage> */}
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors?.device_model_name && touched?.device_model_name
                      }
                    >
                      <FormLabel>Model</FormLabel>
                      <Input
                        {...styles.input}
                        placeholder="Ex: RX-100"
                        name="device_model_name"
                        defaultValue={device?.name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {errors.device_model_name}
                      </FormErrorMessage>
                    </FormControl>
                    <Text style={{ marginTop: 10 }}>
                      {deviceDataAttributeHeadText}
                    </Text>
                    <Text
                      style={{
                        marginTop: 6,
                        fontSize: 12,
                        fontStyle: "normal",
                        fontWeight: 300,
                      }}
                    >
                      {deviceDataAttributeHelperText}
                    </Text>

                    <FieldArray
                      name="device_attributes"
                      style={{
                        marginBottom: 22,
                        padding: 4,
                        marginTop: 4,
                        border: "1px dotted lightgrey",
                        borderRadius: 8,
                      }}
                    >
                      {(arrayHelpers) => (
                        <>
                          {values?.device_attributes.map((val, index) => {
                            const availableUnits =
                              units?.filter(
                                (item) => item?.name === val?.monitoring_value
                              )?.[0]?.measurements || [];

                            return (
                              <Box
                                key={index}
                                style={{
                                  marginTop: 12,
                                  marginBottom: 22,
                                  padding: 16,
                                  marginTop: 6,
                                  border: "1px dotted lightgrey",
                                  borderRadius: 8,
                                }}
                              >
                                <FormControl
                                  marginTop={0}
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]
                                  }
                                >
                                  <Flex
                                    justifyContent={"space-between"}
                                    display={"flex"}
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      {`Monitoring Value ${index + 1}`}
                                    </FormLabel>
                                    <DeleteIcon
                                      cursor="pointer"
                                      w={8}
                                      h={8}
                                      mx={3}
                                      color="red"
                                      boxSize={4}
                                      onClick={() => {
                                        val.monitoring_value
                                          ? handleDeleteModalOpen(val)
                                          : arrayHelpers.remove(index);
                                      }}
                                    />
                                  </Flex>
                                </FormControl>

                                <FormControl
                                  marginTop={0}
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index]
                                      ?.monitoring_value &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]
                                      ?.monitoring_value
                                  }
                                >
                                  <Select
                                    {...styles.input}
                                    placeholder="Select Monitoring Value"
                                    name={`device_attributes.${index}.monitoring_value`}
                                    value={
                                      values?.device_attributes[index]
                                        ?.monitoring_value || ""
                                    }
                                    onChange={handleChange}
                                  >
                                    {[
                                      ...new Set(
                                        units?.map((unit) => unit?.name)
                                      ),
                                    ]?.map((val) => (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    ))}
                                  </Select>
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index]
                                        ?.monitoring_value}
                                  </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                  pt={2}
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index]?.unit_id &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]?.unit_id
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>
                                    Unit{" "}
                                    {values?.device_attributes[index]
                                      ?.monitoring_value || ""}
                                  </FormLabel>
                                  <Select
                                    {...styles.input}
                                    placeholder="Select Unit"
                                    name={`device_attributes.${index}.unit_id`}
                                    value={
                                      values?.device_attributes[index]
                                        ?.unit_id || ""
                                    }
                                    onChange={handleChange}
                                  >
                                    {availableUnits?.map((unit) => (
                                      <option key={unit?.id} value={unit?.id}>
                                        {unit?.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index]?.unit_id}
                                  </FormErrorMessage>
                                </FormControl>

                                <Center pt={2}>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Minimum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      type="number"
                                      placeholder="Minimum"
                                      name={`device_attributes.${index}.min`}
                                      defaultValue={
                                        values?.device_attributes[index]?.min
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].min}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <Box px={2}></Box>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Maximum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      type="number"
                                      placeholder="Maximum"
                                      name={`device_attributes.${index}.max`}
                                      defaultValue={
                                        values?.device_attributes[index]?.max
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].max}
                                    </FormErrorMessage>
                                  </FormControl>
                                </Center>
                              </Box>
                            );
                          })}
                          <Button
                            onClick={() =>
                              arrayHelpers.push({
                                name: "",
                                min: "",
                                max: "",
                                unit_id: "",
                              })
                            }
                            size={"xs"}
                            colorScheme="teal"
                            style={{marginBottom:'3%'}}
                          >
                            {addAttributeButtontext}
                          </Button>
                        </>
                      )}
                    </FieldArray>

                    <Text style={{ marginTop: 10, fontWeight:"bold" }}>
                      Firmware Details
                    </Text>

                    <Card size={{ base: "sm", md: "md" }} style={{marginTop: 6}}>
                      <CardBody>
                        <FormControl
                          isInvalid={errors.name && touched.name}
                        >
                          <FormLabel fontSize={"13px"}> Firmware Name </FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            ref={initialRef}
                            placeholder="Firmware Name"
                            id="name"
                            name="name"
                          />
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl
                          pt={2}
                          isInvalid={errors.version_number && touched.version_number}
                        >
                          <FormLabel fontSize={"13px"}>Version</FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            placeholder="Version name"
                            name="version_number"
                            id="version_number"
                          />
                          <FormErrorMessage>{errors.version_number}</FormErrorMessage>
                        </FormControl>

                        {/* <FormControl
                          pt={2}
                          isInvalid={errors.code_name && touched.code_name}
                        >
                          <FormLabel fontSize={"13px"}>Code Name</FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            placeholder="Code name"
                            name="code_name"
                            id="code_name"
                          />
                          <FormErrorMessage>
                            {errors.code_name}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          pt={2}
                          isInvalid={errors.file && touched.file}
                        >
                          <FormLabel fontSize={"13px"} htmlFor="file">Upload File</FormLabel>
                          <input
                            type="file"
                            id="file"
                            name="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                              setFileName(event.target.files[0]?.name);
                            }}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="file">
                            <Button
                              as="span"
                              leftIcon={<FaUpload />}
                              cursor="pointer"
                              variant="outline"
                              colorScheme="blue"
                            >
                              Choose File
                            </Button>
                          </label>
                          {fileName && <div>{fileName}</div>}
                          <FormErrorMessage>{errors.file}</FormErrorMessage>
                        </FormControl>

                        <FormControl
                          pt={2}
                          isInvalid={errors.is_prod && touched.is_prod}
                        >
                          <Checkbox
                            name="is_prod"
                            id="is_prod"
                            colorScheme="green"
                            isChecked={values?.is_prod}
                            onChange={(e) => {
                              setFieldValue("is_prod", e.target.checked);
                            }}
                          >
                            Production
                          </Checkbox>

                          <FormErrorMessage>{errors.is_prod}</FormErrorMessage>
                        </FormControl> */}
                      </CardBody>
                    </Card>

                    <ModelFooterButtons
                      onClose={onClose}
                      onSave={handleSubmit}
                    />
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={singleDeviceModel}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
      )}
    </>
  );
};

EditDeviceModelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
};

export default EditDeviceModelModal;
