import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getDeviceAPI, getSellerDeviceAPI } from "../redux/helpers/deviceAPI";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import ControllerSearchFilters from "./controllersSearchFilters";

export default function CustomerDevices() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = location.state || {};
  const firmware = useSelector((state) => state?.firmware?.firmwareData);
  const user_type = useSelector((state) => state?.auth?.user_type);
  let devices = []
  const [filteredDevices, setFilteredDevices] = useState(devices);

  if(user_type==="Manufacturer"){
    const activeCustomerOrg = useSelector(
      (state) => state?.customer?.activeCustomerOrg
    );
    devices = useSelector((state) =>
      state?.controllers?.devices?.filter(
        (device) =>
          device?.assigned_to_seller_organization?.id == activeCustomerOrg?.id
      )
    );
  } else {
    devices = useSelector((state) => state?.devices?.devices)
  }

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "50vh",
    },
    indecatorBox: {
      mb: -1,
      pr: 2,
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
    type: "",
    status: "",
    model: "",
  });

  useEffect(() => {
    user_type === "Manufacturer"
      ? dispatch(getDeviceAPI())
      : dispatch(getSellerDeviceAPI());
    setFilteredDevices(devices);
  }, []);

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
      type: "",
      status: "",
      model: "",
    });

    setFilteredDevices(devices);
  };

  const handleSearch = () => {
    const newFilteredDevices = devices?.filter((device) => {
      const nameMatch =
        !searchParams?.name ||
        device?.name
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase()) ||
        device?.serial_number
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase());
      const typeMatch =
        !searchParams?.type ||
        device?.device_type?.id == searchParams?.type?.toLowerCase();
      const statusMatch =
        !searchParams?.status ||
        device?.status?.toLowerCase() === searchParams?.status?.toLowerCase();
      const modelMatch =
        !searchParams?.model ||
        device?.device_model?.id == searchParams?.model?.toLowerCase();

      return nameMatch && typeMatch && statusMatch && modelMatch;
    });

    setFilteredDevices(newFilteredDevices);
  };

  const handleRowClick = (deviceData) => {
    const selectedDevice = devices?.filter(
      (device) => device.serial_number == deviceData.serial_number
    );
    dispatch(setActiveDevice(selectedDevice[0]));
    console.log(deviceData,'DeviceData')
    user_type === "Manufacturer" ?
    navigate(
      `/customers/${deviceData?.assigned_to_seller?.organization}/${deviceData?.device_model?.name}`,
      { state: { deviceData } }
    ) : 
    navigate(
      `/products/${deviceData?.device_type?.name}/${deviceData?.device_model?.name}`,
      { state: { deviceData } }
    )
  };

  return (
    <Flex flexDirection={"column"}>
      <Box {...styles.topBox}>
        <ControllerSearchFilters
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      </Box>

      <TableContainer {...styles.tableContainer} px={4}>
        <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th width="20%">Serial Number</Th>
              <Th width="12%">PRODUCT</Th>
              <Th width="12%">Model</Th>
              <Th width="12%">Revision</Th>
              <Th width="12%">Date Produced</Th>
              {/* <Th width="12%">Tested By</Th> */}
              <Th width="12%">Date Tested</Th>
              <Th width="12%">Firmware</Th>
              <Th width="12%">MONITORING VALUE THRESHOLD</Th>
               {/* <Th width="12%">FIRMWARE</Th>
              <Th width="12%">MANUFACTURER</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {filteredDevices?.map((device, index) => {
              return (
                <Tr
                  key={index}
                  onClick={() => handleRowClick(device)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>
                    <Flex>
                      <Center>
                        <Box {...styles.indecatorBox}>
                          <GoDotFill
                            color={
                              device?.status === "online" ? "green" : "red"
                            }
                          />
                        </Box>
                        <Box>
                          <Text {...styles.deviceName}>
                            {" "}
                            #{device?.serial_number}{" "}
                          </Text>
                          {/* <Text {...styles.deviceModel}>
                            {device?.name == null ? "unassigned" : device?.name}{" "}
                          </Text> */}
                        </Box>
                      </Center>
                    </Flex>
                  </Td>
                  <Td>
                    <Text>{device?.device_type?.name}</Text>
                  </Td>
                  <Td>
                    <Text>{device?.device_model?.name}</Text>
                  </Td>
                  <Td>
                    <Text>NA</Text>
                  </Td>
                  <Td>
                    <Text>{new Date(device?.date_produced).toLocaleDateString()}</Text>
                  </Td>
                  <Td>
                    <Text>{new Date(device?.date_tested).toLocaleDateString()}</Text>
                  </Td>
                  <Td>
                    <Text>{device?.firmware_version}</Text>
                  </Td>
                  <Td>
                    {device?.data_points?.map((val) => {
                      return (
                        <div>
                          <Text fontSize={'x-small'}>  {val?.minimum} - {val?.maximum} {val?.unit?.unit} </Text>
                        </div>
                      );
                    })}
                  </Td>
                  {/* <Td>
                    {firmware
                      ?.filter((val) => {
                        return val?.version_number == device?.firmware_version;
                      })
                      .map((val) => {
                        return val?.name;
                      })}
                  </Td> */}
                  {/* <Td>{device?.manufacturer.organization}</Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
