import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiBuildings, BiDevices, BiMicrochip } from "react-icons/bi";
import { BsCalendar, BsFillPersonBadgeFill, BsPerson } from "react-icons/bs";
import {
  MdBluetoothConnected,
  MdBuild,
  MdCalendarMonth,
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdOutlineWifi,
} from "react-icons/md";

import { EditIcon, SettingsIcon } from "@chakra-ui/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDeviceAPI,
  deviceConfigHistoryAPI,
  getModelsAPI,
  getTypesAPI,
  sellerDeviceConfigHistoryAPI,
  updateDevicesAPI,
} from "../redux/helpers/controllerAPI";
import { getOrganizationsAPI } from "../redux/helpers/customerAPI";
import {
  addSellerDeviceDataPointsAPI,
  getDeviceAPI,
  getSellerDeviceAPI,
  removeSellerDeviceDataPointAPI,
  updateDeviceDataPointsAPI,
  updateSellerAlertRecipientAPI,
  updateSellerDeviceDataPointsAPI,
} from "../redux/helpers/deviceAPI";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceAttributesModal from "./editDeviceAttributesModal";
import EditDeviceModal from "./editDeviceModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";
import { EditDeviceInstallationPlace } from "./editDeviceInstallationPlace";
import { AlertRecipientTable } from "./AlertRecipientTable";

function DeviceDetails() {
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deviceData = useSelector((state) => state?.controllers?.activeDevice);
  const currentDevice = useSelector((state) =>
    state?.devices?.devices?.filter((val) => val?.id === deviceData?.id)
  );
  const firmware = useSelector((state) =>
    state?.firmware?.firmwareData?.filter(
      (f) =>
        f?.device_model?.id === deviceData?.device_model?.id &&
        f?.device_type?.id === deviceData?.device_type?.id
    )
  );

  const configHistory = useSelector(
    (state) => state?.controllers?.activeDeviceConfigHistory
  );
  const userProfile = useSelector(
    (state) => state?.auth?.userProfile?.role?.name
  );
  const user_type = useSelector((state) => state?.auth?.user_type);

  const navigate = useNavigate();

  const deviceConfigHistoryAPICall =
    user_type === "Manufacturer"
      ? deviceConfigHistoryAPI
      : sellerDeviceConfigHistoryAPI;
  const getDeviceAPICall =
    user_type === "Manufacturer" ? getDeviceAPI : getSellerDeviceAPI;
  const updateDeviceDataPointsAPICall =
    user_type === "Manufacturer"
      ? updateDeviceDataPointsAPI
      : updateSellerDeviceDataPointsAPI;
  const addSellerDeviceDataPointsAPICall =
    user_type === "Manufacturer"
      ? updateDeviceDataPointsAPI
      : addSellerDeviceDataPointsAPI;

  useEffect(() => {
    const payload = {
      device_id: currentDevice?.length > 0 && currentDevice[0]?.id,
    };

    dispatch(deviceConfigHistoryAPICall(payload))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });

    console.log("deviceData", deviceData);
  }, []);

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "end",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
      mt: 1,
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    subheaderTextleft: {
      width: "40%",
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    firmwareText: {
      fontSize: "10px",
      color: "#30C268",
      mt: "1",
    },
    greenIcon: {
      color: "#30C268",
    },
    readingsBox: {
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    readingsTextContainer: {
      alignItems: "center",
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    readingsTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
    pressureReadingBox: {
      mt: "-2",
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingTextContainer: {
      alignItems: "center",
    },
    pressureReadingText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    pressureReadingTopic: {
      // pt: "2",
      fontSize: "16px",
      // color: "#929FB1",
      fontWeight: "medium",
    },
  };
  const [singleDevice, setSingleDevice] = useState(deviceData);
  const toast = useToast();
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isEditCustomerInfoModelOpen, setIsEditCustomerInfoModelOpen] =
    useState(false);
  const [singleDeviceModel, setSingleDeviceModel] = useState();
  const handleEditModelCloseModal = () => {
    setIsEditModelOpen(false);
  };

  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handlCloseEditCustomerInfo = () => {
    setIsEditCustomerInfoModelOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModifyClick = () => {
    setIsModalOpen(true);
    setSingleDevice({ ...currentDevice[0], update: true });
  };

  const reloadDataForAccount = () => {
    dispatch(getTypesAPI());
    dispatch(getModelsAPI());
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);
      const resDeleteApi = dispatch(deleteDeviceAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage("Product Deleted successfully");
          handleCloseDeleteModal();
          dispatch(getOrganizationsAPI());
          dispatch(
            deviceConfigHistoryAPICall({
              device_id: deviceData?.id,
            })
          );
          dispatch(getDevicgetDeviceAPICalleAPI());
          setisSuccessModalOpen(true);
          navigate(-1);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Unit",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSaveDevice = (payload) => {
    setLoading(true);
    const addPayload = { ...payload };
    payload = { ...payload };

    addPayload.data_points = payload?.data_points?.filter(
      (dataPoint) => Object.keys(dataPoint).length > 0
    );

    // debugger
    // let updateModelres =
    //   payload?.data_points?.length === currentDevice[0]?.data_points?.length
    //     ? dispatch(updateDeviceDataPointsAPICall(payload))
    //     : dispatch(addSellerDeviceDataPointsAPICall(addPayload));
    
    let updateModelres;

    let didAttributesChange = _.isEqual(payload?.data_points, currentDevice[0]?.data_points)
    console.log("onSaveDevice", didAttributesChange)
    if (didAttributesChange == false ) {
      updateModelres = dispatch(addSellerDeviceDataPointsAPICall(addPayload));
    } else {
      updateModelres = dispatch(updateDeviceDataPointsAPICall(payload))
    }

    updateModelres
      .then((updatedData) => {
        if (updatedData?.payload?.success) {
          const updatedDeviceres = dispatch(
            getDeviceAPICall({
              serial_number: currentDevice[0]?.serial_number,
              id: currentDevice[0]?.id,
            })
          );
          updatedDeviceres.then((data) => {
            if (data?.payload?.success) {
              const newUpdatedDevice =
                user_type === "Manufacturer"
                  ? data?.payload?.data
                  : data?.payload?.data.filter(
                      (dev) => dev?.id == currentDevice[0]?.id
                    );
              console.log(newUpdatedDevice[0], "uPDATED DEVICE");
              dispatch(setActiveDevice(newUpdatedDevice[0]));
              dispatch(getDeviceAPICall());
            }
          });
          dispatch(
            deviceConfigHistoryAPICall({
              device_id: currentDevice?.length > 0 && currentDevice[0]?.id,
            })
          );
          handleEditModelCloseModal();
          toast({
            title: "Success",
            description:
              updatedData?.payload?.message ||
              "Successfully updated device information",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
          setLoading(false);
        } else {
          dispatch(getDeviceAPICall()); // WHy the heck models is called on this page?
          setLoading(false);
          toast({
            title: "Error",
            description:
              updatedData?.payload?.message || updatedData?.payload?.error,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const onSaveAlertRecipent = (payload) => {
    setLoading(true);
    payload = { ...payload };

    let updateModelres = dispatch(updateSellerAlertRecipientAPI(payload));
    updateModelres
      .then((updatedData) => {
        if (updatedData?.payload?.success) {
          const updatedDeviceres = dispatch(
            getDeviceAPICall({
              serial_number: currentDevice[0]?.serial_number,
              id: currentDevice[0]?.id,
            })
          );
          updatedDeviceres.then((data) => {
            if (data?.payload?.success) {
              const newUpdatedDevice = data?.payload?.data.filter(
                (dev) => dev?.id == currentDevice[0]?.id
              );
              dispatch(setActiveDevice(newUpdatedDevice[0]));
              dispatch(getDeviceAPICall());
            }
          });
          toast({
            title: "Success",
            description:
              updatedData?.payload?.message ||
              "Successfully updated device alert recipient",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
          setLoading(false);
        } else {
          dispatch(getDeviceAPICall());
          setLoading(false);
          toast({
            title: "Error",
            description:
              updatedData?.payload?.message || updatedData?.payload?.error,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleEditModelOpen = (device) => {
    setIsEditModelOpen(true);
    setSingleDeviceModel(device);
  };

  const handlEditCustomerInfo = (deviceData) => {
    setIsEditCustomerInfoModelOpen(true);
    setSingleDeviceModel(deviceData);
  };

  /**
   * use this to drop a data_point permanently from device.
   * @param {*} payload
   */
  const removeDeviceDataPoint = (payload) => {
    setLoading(true);
    dispatch(removeSellerDeviceDataPointAPI(payload))
      .then((response) => {
        console.log("removeDeviceDataPoint", response);

        const updatedDeviceres = dispatch(
          getDeviceAPICall({
            serial_number: currentDevice[0]?.serial_number,
            id: currentDevice[0]?.id,
          })
        );
        updatedDeviceres.then((data) => {
          if (data?.payload?.success) {
            const newUpdatedDevice = data?.payload?.data.filter(
              (dev) => dev?.id == currentDevice[0]?.id
            );
            dispatch(setActiveDevice(newUpdatedDevice[0]));
            dispatch(getDeviceAPICall());
          }
        });
        toast({
          title: "Success",
          description:
            updatedData?.payload?.message ||
            "Successfully updated device alert recipient",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <Card
        size={{ base: "sm", md: "md" }}
        style={{ display: "flex" }}
        key={currentDevice?.length > 0 && currentDevice[0]?.id}
      >
        <CardBody>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            flexWrap="wrap"
          >
            <Box width={{ base: "100%", md: "20rem" }} mb={{ base: 4, md: 0 }}>
              <Flex gap={2}>
                <Heading {...styles.deviceHeader}>
                  {currentDevice?.length > 0 &&
                    currentDevice[0]?.device_type?.name}
                </Heading>
                <MdOutlineWifi
                  color={
                    currentDevice?.length > 0 &&
                    currentDevice[0]?.status === "online"
                      ? "#30C268"
                      : "red"
                  }
                />
                <MdBluetoothConnected
                  color={
                    currentDevice?.length > 0 &&
                    currentDevice[0]?.status === "online"
                      ? "#30C268"
                      : "red"
                  }
                />
              </Flex>
              <Flex {...styles.subheaderItems}>
                <BiDevices boxsize={1} />
                <Text {...styles.subheaderTextleft}>Serial No</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0 && currentDevice[0]?.serial_number}
                </Text>
              </Flex>

              <Flex {...styles.subheaderItems}>
                <BsPerson boxsize={1} />
                <Text {...styles.subheaderTextleft}>Tested By</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0
                    ? currentDevice[0]?.tested_by?.first_name
                      ? currentDevice[0]?.tested_by?.first_name +
                        " " +
                        (currentDevice[0]?.tested_by?.last_name
                          ? currentDevice[0]?.tested_by?.last_name
                          : null)
                      : "N/A"
                    : "N/A"}
                </Text>
              </Flex>
              <Flex {...styles.subheaderItems}>
                <BsCalendar boxsize={1} />
                <Text {...styles.subheaderTextleft}>Date Tested</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {new Date(currentDevice[0]?.date_tested).toLocaleDateString()}
                </Text>
              </Flex>

              <Flex {...styles.subheaderItems}>
                <BiMicrochip boxsize={1} />
                <Text {...styles.subheaderTextleft}>Model</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0 &&
                    currentDevice[0]?.device_model?.name}
                </Text>
              </Flex>
              <Flex {...styles.subheaderItems}>
                <BiDevices boxsize={1} />
                <Text {...styles.subheaderTextleft}>Firmware</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {deviceData?.firmware && deviceData?.firmware?.name
                    ? deviceData?.firmware?.name
                    : currentDevice[0]?.firmware?.name}
                </Text>
              </Flex>

              {/* <Flex {...styles.subheaderItems}>
                <MdOutlinePhone boxsize={1} />
                <Text {...styles.subheaderTextleft}>Phone </Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0
                    ? currentDevice[0]?.organization?.phone_number
                    : "N/A"}
                </Text>
              </Flex> */}

              {isMobile && (
                <Flex {...styles.subheaderItems}>
                  <MdBuild />
                  <Text {...styles.subheaderText}>
                    {currentDevice?.length > 0 &&
                      currentDevice[0]?.firmware?.version_number}
                  </Text>
                  {currentDevice?.length > 0 &&
                  currentDevice[0]?.firmware?.version_number === "4.0" ? (
                    <Text fontSize={10} color="#30C268" pl={6}>
                      Latest
                    </Text>
                  ) : (
                    <Text fontSize={10} color="blue" pl={6}>
                      Update
                    </Text>
                  )}
                </Flex>
              )}
            </Box>
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              flexWrap="wrap"
              width={{ base: "100%", md: "auto" }}
              bg={"#3b61ff4f"}
              borderRadius={8}
              p={3}
              mt={4}
            >
              <Box
                width={{ base: "100%", md: "20rem" }}
                mb={{ base: 4, md: 0 }}
              >
                <Flex
                  gap={2}
                  justifyContent="space-between"
                  style={{ marginBottom: "2%" }}
                >
                  <Text
                    fontFamily="'Poppins', sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    fontStyle="normal"
                    letterSpacing=".05em"
                    textTransform="uppercase"
                  >
                    CUSTOMER INFORMATION
                  </Text>
                  <Button
                    size="xs"
                    colorScheme="teal"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlEditCustomerInfo(deviceData);
                    }}
                  >
                    Modify
                    <EditIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                </Flex>

                {currentDevice?.length > 0 &&
                  currentDevice[0]?.assigned_to_seller &&
                  user_type === "Manufacturer" && (
                    <Flex {...styles.subheaderItems}>
                      <BiBuildings boxsize={1} />
                      <Text {...styles.subheaderTextleft}>Customer</Text>
                      <Text {...styles.subheaderText}>
                        :{" "}
                        {currentDevice[0]?.assigned_to_seller_organization?.name
                          ? currentDevice[0]?.assigned_to_seller_organization
                              ?.name
                          : currentDevice[0]?.assigned_to_customer_organization
                                ?.name
                            ? currentDevice[0]
                                ?.assigned_to_customer_organization?.name
                            : "Unassigned"}
                      </Text>
                    </Flex>
                  )}

                {currentDevice?.length > 0 &&
                  currentDevice[0]?.assigned_to_seller &&
                  user_type === "Manufacturer" && (
                    <Flex {...styles.subheaderItems}>
                      <BsFillPersonBadgeFill boxsize={1} />
                      <Text {...styles.subheaderTextleft}>Manager</Text>
                      <Text {...styles.subheaderText}>
                        :{" "}
                        {currentDevice[0]?.assigned_to_seller?.first_name
                          ? currentDevice[0]?.assigned_to_seller?.first_name
                          : currentDevice[0]?.assigned_to_seller?.last_name
                            ? currentDevice[0]?.assigned_to_seller?.last_name
                            : "Unassigned"}
                      </Text>
                    </Flex>
                  )}

                {currentDevice?.length > 0 &&
                  currentDevice[0]?.assigned_to_seller && (
                    <Flex {...styles.subheaderItems}>
                      <MdOutlineLocationOn boxsize={1} />
                      <Text {...styles.subheaderTextleft}>Where installed</Text>
                      <Text {...styles.subheaderText}>
                        :{" "}
                        {deviceData?.where_installed
                          ? deviceData?.where_installed?.address
                          : deviceData?.assigned_to_seller_organization
                            ? deviceData?.assigned_to_seller_organization
                                ?.location?.address
                            : "N/A"}
                      </Text>
                    </Flex>
                  )}
                <Flex {...styles.subheaderItems}>
                  <MdCalendarMonth boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Installation Date</Text>
                  <Text {...styles.subheaderText}>
                    :{" "}
                    {deviceData?.purchase_date
                      ? moment(deviceData?.purchase_date).format("YYYY-MM-DD")
                      : "N/A"}
                  </Text>
                </Flex>

                {isMobile && (
                  <Flex {...styles.subheaderItems}>
                    <MdBuild />
                    <Text {...styles.subheaderText}>
                      {currentDevice?.length > 0 &&
                        currentDevice[0]?.firmware?.version_number}
                    </Text>
                    {currentDevice?.length > 0 &&
                    currentDevice[0]?.firmware?.version_number === "4.0" ? (
                      <Text fontSize={10} color="#30C268" pl={6}>
                        Latest
                      </Text>
                    ) : (
                      <Text fontSize={10} color="blue" pl={6}>
                        Update
                      </Text>
                    )}
                  </Flex>
                )}
              </Box>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <Flex
        gap={4}
        justifyContent={{ base: "center", md: "space-between" }}
        flexWrap="wrap"
      >
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH="30vh"
          overflow="auto"
          width={{ base: "100%", md: "49%" }}
        >
          <CardHeader>
            <Flex justifyContent="space-between">
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase"
              >
                Current Configuration
              </Text>
              <Button
                size="xs"
                colorScheme="teal"
                color="white"
                // isDisabled={userProfile === "Admin"? false : true}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditModelOpen(currentDevice[0]);
                }}
              >
                Modify
                <SettingsIcon
                  w={4}
                  h={4}
                  color="white"
                  marginLeft={2}
                  boxSize={3}
                />
              </Button>
            </Flex>
          </CardHeader>
          <CardBody mt={0} pt={0}>
            {currentDevice?.length > 0 &&
            currentDevice[0]?.data_points?.length > 0 ? (
              <>
                <SimpleGrid
                  minChildWidth="60px"
                  mt={2}
                  justifyContent="space-between"
                >
                  <Text {...styles.pressureReadingTopic} fontSize="sm">
                    Parameters Monitored
                  </Text>
                  <Text
                    {...styles.pressureReadingText}
                    fontWeight="normal"
                    justifyContent="flex-end"
                    display="flex"
                    fontSize="sm"
                  >
                    Min
                  </Text>
                  <Text
                    {...styles.pressureReadingText}
                    fontWeight="normal"
                    justifyContent="flex-end"
                    display="flex"
                    fontSize="sm"
                  >
                    Max
                  </Text>
                </SimpleGrid>
                {currentDevice?.length > 0 &&
                  currentDevice[0]?.data_points?.map((dataPt, index) => (
                    <Box key={dataPt?.id || index}>
                      <SimpleGrid
                        minChildWidth="60px"
                        spacing={4}
                        mt={2}
                        justifyItems="space-between"
                      >
                        <Text {...styles.pressureReadingTopic} fontSize="sm">
                          {dataPt?.unit?.type}
                        </Text>
                        <Text
                          {...styles.pressureReadingText}
                          justifyContent="flex-end"
                          display="flex"
                        >
                          {dataPt?.minimum} {dataPt?.unit?.unit}
                        </Text>
                        <Text
                          {...styles.pressureReadingText}
                          justifyContent="flex-end"
                          display="flex"
                        >
                          {dataPt?.maximum} {dataPt?.unit?.unit}
                        </Text>
                      </SimpleGrid>
                    </Box>
                  ))}
              </>
            ) : (
              <span> No paramerters monitored </span>
            )}
          </CardBody>
        </Card>
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH="30vh"
          overflow="auto"
          width={{ base: "100%", md: "49%" }}
        >
          <CardHeader>
            <Flex justifyContent="space-between">
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase"
              >
                Settings Change log{" "}
                {configHistory?.length ? `(${configHistory?.length})` : null}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            {configHistory?.map((configData, index) => (
              <Box key={configData?.id || index}>
                <SimpleGrid
                  minChildWidth="60px"
                  spacing={4}
                  mt={2}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Flex direction="column">
                    <Text {...styles.pressureReadingTopic} fontSize="sm">
                      {configData?.author?.email}
                    </Text>
                    <Text {...styles.timeStamp} fontSize="xs">
                      {moment(configData?.revision_timestamp).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      {...styles.pressureReadingText}
                      fontWeight="normal"
                      justifyContent="flex-end"
                      display="flex"
                      fontSize="sm"
                    >
                      Parameters Monitored
                    </Text>
                    <Text {...styles.pressureReadingText}>
                      {configData?.unit?.type}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      {...styles.pressureReadingText}
                      fontWeight="normal"
                      justifyContent="flex-end"
                      display="flex"
                      fontSize="sm"
                    >
                      Min
                    </Text>
                    <Text {...styles.pressureReadingText}>
                      {configData?.minimum} {configData?.unit?.unit}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      {...styles.pressureReadingText}
                      fontWeight="normal"
                      justifyContent="flex-end"
                      display="flex"
                      fontSize="sm"
                    >
                      Max
                    </Text>
                    <Text {...styles.pressureReadingText}>
                      {configData?.maximum} {configData?.unit?.unit}
                    </Text>
                  </Flex>
                </SimpleGrid>
                <Divider />
              </Box>
            ))}

            {configHistory?.length === 0 ? (
              <Box mt={-2}> No recent changes </Box>
            ) : (
              <span></span>
            )}
          </CardBody>
        </Card>
      </Flex>
      <AlertRecipientTable
        device={currentDevice[0]}
        onSaveAlertRecipent={onSaveAlertRecipent}
      />
      {isEditModelOpen && (
        <EditDeviceAttributesModal
          isOpen={isEditModelOpen}
          onClose={handleEditModelCloseModal}
          setIsModalOpen={setIsEditModelOpen}
          onSave={onSaveDevice}
          device={singleDeviceModel}
          removeDeviceDataPoint={removeDeviceDataPoint}
        />
      )}
      {isEditCustomerInfoModelOpen && (
        <EditDeviceInstallationPlace
          isOpen={isEditCustomerInfoModelOpen}
          onClose={handlCloseEditCustomerInfo}
          setIsEditCustomerInfoModelOpen={setIsEditCustomerInfoModelOpen}
          device={singleDeviceModel}
        />
      )}
      {isModalOpen && (
        <EditDeviceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          device={singleDevice}
          reloadDataForAccount={reloadDataForAccount}
          setisSuccessModalOpen={setisSuccessModalOpen}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={singleDevice}
          onSave={onSaveDeleteModel}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
}

export default DeviceDetails;
