// import React, { useState } from "react";
// import {
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Button,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   useDisclosure,
//   FormControl,
//   FormLabel,
//   Checkbox,
//   CheckboxGroup,
//   Stack,
//   Card,
//   CardBody,
//   Flex,
//   CardHeader,
//   Text,
// } from "@chakra-ui/react";
// import axios from "axios";
// import { EditIcon } from "@chakra-ui/icons";
// import { useSelector } from "react-redux";

// const AlertRecipientTable = (device, onSaveAlertRecipent) => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [selectedRecipient, setSelectedRecipient] = useState(null);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const user_type = useSelector((state) => state?.auth?.user_type);

//   const teamMemberData = useSelector(
//     (state) => state?.teamMember?.teamMemberData
//   );

//   console.log(teamMemberData, "teamMemberData");

//   // Handle Update button click
//   const handleUpdateClick = (recipient) => {
//     setSelectedRecipient(recipient);
//     onOpen();
//   };

//   // Handle checkbox group change
//   const handleSelectChange = (values) => {
//     setSelectedOptions(values);
//   };

//   // Handle form submit
//   const handleSubmit = async () => {
//     const data = {
//       recipientId: selectedRecipient.id,
//       selectedOptions,
//     };

//     // Call the API with axios
//     try {
//       await axios.post("/api/recipients/update", data);
//       alert("Updated successfully!");
//     } catch (error) {
//       console.error("Error updating recipient:", error);
//       alert("Failed to update.");
//     }
//     onClose(); // Close the modal after submission
//   };

//   return (
//     <>
//       <Card size={{ base: "sm", md: "md" }} style={{ display: "flex" }}>
//         <CardHeader>
//           <Flex justifyContent="space-between">
//             <Text
//               fontFamily="'Poppins', sans-serif"
//               fontWeight={600}
//               fontSize={14}
//               fontStyle="normal"
//               letterSpacing=".05em"
//               textTransform="uppercase"
//             >
//               Alert Recipients
//             </Text>
//             {user_type !== "Manufacturer" ? (
//               <Button
//                 size="xs"
//                 colorScheme="teal"
//                 color="white"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleUpdateClick();
//                 }}
//               >
//                 Update Alert Recipients
//                 <EditIcon
//                   w={4}
//                   h={4}
//                   color="white"
//                   marginLeft={2}
//                   boxSize={3}
//                 />
//               </Button>
//             ) : (
//               <></>
//             )}
//           </Flex>
//         </CardHeader>

//         <CardBody>
//           <Flex
//             justifyContent={{ base: "center", md: "space-between" }}
//             flexWrap="wrap"
//           >
//             <Table variant="simple">
//               <Thead>
//                 <Tr>
//                   <Th>First Name</Th>
//                   <Th>Last Name</Th>
//                   <Th>Email</Th>
//                   <Th>Phone Number</Th>
//                 </Tr>
//               </Thead>

//               {/* <Tbody>
//                 {devicerecipientsData.map((recipient) => (
//                   <Tr key={recipient.id}>
//                     <Td>{recipient.first_name}</Td>
//                     <Td>{recipient.last_name}</Td>
//                     <Td>{recipient.email}</Td>
//                     <Td>{recipient.phone_number}</Td>
//                   </Tr>
//                 ))}
//               </Tbody> */}
//             </Table>
//           </Flex>
//         </CardBody>
//       </Card>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Update Recipient</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <FormControl>
//               <FormLabel>Select Options</FormLabel>
//               <CheckboxGroup
//                 value={selectedOptions}
//                 onChange={handleSelectChange}
//               >
//                 <Stack spacing={3}>
//                   {teamMemberData.map((member) => (
//                     <Checkbox
//                       colorScheme="green"
//                       key={member?.id}
//                       value={member?.id}
//                     >
//                       {member?.first_name} {member?.last_name}
//                     </Checkbox>
//                   ))}
//                 </Stack>
//               </CheckboxGroup>
//             </FormControl>
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
//               Submit
//             </Button>
//             <Button onClick={onClose}>Cancel</Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// };

// export default AlertRecipientTable;

//

import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Card,
  CardBody,
  Flex,
  CardHeader,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { EditIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const AlertRecipientTable = ({ onSaveAlertRecipent, device }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const user_type = useSelector((state) => state?.auth?.user_type);

  const teamMemberData = useSelector(
    (state) => state?.teamMember?.teamMemberData
  );

  // console.log(teamMemberData, "teamMemberData", device);

  const handleUpdateClick = () => {
    onOpen();
  };

  const handleSelectChange = (event) => {
    const selectedMemberId = event.target.value;
    console.log(selectedMemberId, "selectedMemberID");
    setSelectedOption(selectedMemberId);
    const selectedMember = teamMemberData?.find(
      (member) => member?.id == selectedMemberId
    );
    console.log(selectedMember, "selectedMemberselectedMember");
    setSelectedRecipient(selectedMember);
  };

  // Handle form submit
  const handleSubmit = async () => {
    const data = {
      first_name: selectedRecipient?.first_name,
      last_name: selectedRecipient?.last_name,
      role_id: selectedRecipient?.role?.id,
      email: selectedRecipient?.email,
      phone_number: selectedRecipient?.phone_number,
      customer_id: selectedRecipient?.id,
      device_id: device?.id,
    };
    onSaveAlertRecipent(data);
    onClose();
  };

  return (
    <>
      <Card size={{ base: "sm", md: "md" }} style={{ display: "flex" }}>
        <CardHeader>
          <Flex justifyContent="space-between">
            <Text
              fontFamily="'Poppins', sans-serif"
              fontWeight={600}
              fontSize={14}
              fontStyle="normal"
              letterSpacing=".05em"
              textTransform="uppercase"
            >
              Alert Recipients
            </Text>
            {user_type !== "Manufacturer" ? (
              <Button
                size="xs"
                colorScheme="teal"
                color="white"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdateClick();
                }}
              >
                Update Recipients
                <EditIcon
                  w={4}
                  h={4}
                  color="white"
                  marginLeft={2}
                  boxSize={3}
                />
              </Button>
            ) : (
              <></>
            )}
          </Flex>
        </CardHeader>

        <CardBody>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            flexWrap="wrap"
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Email</Th>
                  <Th>Phone Number</Th>
                </Tr>
              </Thead>

              {/* Example data rendering */}
              <Tbody>
                {device.alert_recipient?.map((recipient) => (
                <Tr key={recipient?.id}>
                  <Td>{recipient?.first_name}</Td>
                  <Td>{recipient?.last_name}</Td>
                  <Td>{recipient?.email}</Td>
                  <Td>{recipient?.phone_number}</Td>
                  {/* {console.log('alert_recipient', recipient)} */}
                </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Alert Recipient</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Select a Team Member</FormLabel>
              <Select
                placeholder="Select team member"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                {teamMemberData?.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.first_name} {member.last_name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            
            <Button size={"sm"} mr={3} onClick={onClose}>Cancel</Button>
            <Button size={"sm"}
                width="8rem"
                colorScheme="facebook" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

AlertRecipientTable.propTypes = {
  onSaveAlertRecipient: PropTypes.func.isRequired,
  device: PropTypes.object,
};
