import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import ModelFooterButtons from "./modelFooterButtons";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  getDeviceAPI,
  getSellerDeviceAPI,
  updateDeviceInstalledLocationAPI,
  updateSellerDeviceInstalledLocationAPI,
} from "../redux/helpers/deviceAPI";
import { setActiveDevice } from "../redux/reducers/controllersReducer";

export const EditDeviceInstallationPlace = ({
  isOpen,
  onClose,
  setIsEditCustomerInfoModelOpen,
  device,
}) => {
  console.log(device, "customercustomer");
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const user_type = useSelector((state) => state?.auth?.user_type);
  const dispatch = useDispatch();
  const toast = useToast();

  const updateDeviceInstalledLocationAPICall =
    user_type === "Manufacturer"
      ? updateDeviceInstalledLocationAPI
      : updateSellerDeviceInstalledLocationAPI;
  const getDeviceAPICall =
    user_type === "Manufacturer" ? getDeviceAPI : getSellerDeviceAPI;

  const AddCustomerValidationSchema = Yup.object().shape({
    full_address: Yup.string().required("First Name is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };
  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Installation Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                full_address: device?.where_installed?.address || "",
                city: device?.where_installed?.city || "",
                region: device?.where_installed?.region || "",
                country: device?.where_installed?.country || "",
                postalcode: device?.where_installed?.postalcode || "",
              }}
              validationSchema={AddCustomerValidationSchema}
              onSubmit={(values) => {
                values.device_id = device?.id;

                let updateLocationRes = dispatch(
                  updateDeviceInstalledLocationAPICall(values)
                );
                updateLocationRes
                  .then((updatedData) => {
                    if (updatedData?.payload?.success) {
                      const updatedDeviceres = dispatch(
                        getDeviceAPICall({
                          id: device?.id,
                        })
                      );
                      updatedDeviceres.then((data) => {
                        if (data?.payload?.success) {
                          const newUpdatedDevice =
                            user_type === "Manufacturer"
                              ? data?.payload?.data
                              : data?.payload?.data.filter(
                                  (dev) => dev?.id == device?.id
                                );
                          dispatch(setActiveDevice(newUpdatedDevice[0]));
                          dispatch(getDeviceAPICall());
                        }
                      });
                      toast({
                        title: "Success",
                        description:
                          updatedData?.payload?.message ||
                          "Successfully updated device information",
                        status: "success",
                        duration: 6000,
                        isClosable: true,
                      });
                      setIsEditCustomerInfoModelOpen(false);
                    } else {
                      dispatch(getDeviceAPICall());
                      toast({
                        title: "Error",
                        description:
                          updatedData?.payload?.message ||
                          updatedData?.payload?.error,
                        status: "error",
                        duration: 6000,
                        isClosable: true,
                      });
                      setIsEditCustomerInfoModelOpen(false);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {({ handleSubmit, errors, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.full_address && touched.full_address}
                  >
                    <FormLabel>Full Address</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="full_address"
                      name="full_address"
                      type="text"
                      placeholder="Enter Full address"
                    />
                    <FormErrorMessage>{errors?.full_address}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.city && touched.city}
                  >
                    <FormLabel>city</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="city"
                      name="city"
                      type="text"
                      placeholder="Enter City"
                    />
                    <FormErrorMessage>{errors?.city}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.region && touched.region}
                  >
                    <FormLabel>Region</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="region"
                      name="region"
                      type="text"
                      placeholder="Enter Region"
                    />
                    <FormErrorMessage>{errors?.region}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.country && touched.country}
                  >
                    <FormLabel>country</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="country"
                      name="country"
                      type="text"
                      placeholder="Enter Country"
                    />
                    <FormErrorMessage>{errors?.country}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={errors.postalcode && touched.postalcode}
                  >
                    <FormLabel mt={4}>Postal Code</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="postalcode"
                      name="postalcode"
                      type="number"
                      placeholder="Enter Postal code"
                    />
                    <FormErrorMessage>{errors?.postalcode}</FormErrorMessage>
                  </FormControl>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditDeviceInstallationPlace.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object,
};
