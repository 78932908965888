import { Card, CardBody, CardHeader } from "@chakra-ui/card";
import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading
} from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
// import RecentAlertList from "../components/recentAlertList";
import DeviceWatchlist from "../components/deviceWatchlist";
import Breadcrumbs from "../components/breadcrumbs";
import ReactECharts from "echarts-for-react";
import DashboardUserAssetCountSection from "../components/dashboardUserAssetCountSection";
import { getDashboardDataAPI, getSellerDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { useDispatch, useSelector } from "react-redux";
import DashboardCustomerTeamMembers from "../components/dashboardCustomerTeamMembers";
import DashboardDeviceList from "../components/dashboardDeviceList";
import { getOrganizationsAPI, getSellerOrganizationsAPI } from "../redux/helpers/customerAPI";
import { Text } from "@chakra-ui/react";
import { getDeviceAPI, getSellerDeviceAPI } from "../redux/helpers/deviceAPI";
import Loader from "../components/loader";

function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const summaryData = useSelector((state) => state?.summary?.summaryData);

  const user_type = useSelector((state) => state?.auth?.user_type);
  // setCustomersCnt(summaryDataCustomerCount);
  const options = {
    title: {
      text: "Device Data Over Time",
      left: "left",
      padding: [20, 0, 0, 20]
    },
    tooltip: {
      trigger: "axis"
    },
    legend: {
      data: ["Device 1", "Device 2", "Device 3", "Device 4", "Device 5"],
      top: "12%"
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "02 PM",
        "04 PM",
        "06 PM",
        "08 PM",
        "10 PM",
        "12 PM",
        "02 AM",
        "04 AM",
        "06 AM",
        "08 AM",
        "10 AM",
        "12 AM"
      ]
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        name: "Device 1",
        type: "line",
        stack: "Total",
        // data: [25, 30, 32, 34, 30, 39, 45, 50, 60, 64, 70],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: "Device 2",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: "Device 3",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: "Device 4",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: "Device 5",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ]
  };
  const styles = {
    topGrid: {
      gap: 4,
      templateColumns: "repeat(2, 1fr)"
    },
    topContainerWatchlist: {
      overflowY: "auto",
      h: { base: "82vh", md: "72vh", lg: "77vh" },
      w: { base: "100%", lg: "50vw" },
      colSpan: { base: "2", md: "2", lg: "1" },
      pr: 2
    },
    cardHeader: {
      size: "md"
    },
    recentAlertsCard: {
      w: { base: "100%", lg: "31vw" },
      overflowY: "auto",
      h: "77vh",
      size: "sm"
    },
    sideList: {
      colSpan: { base: "2", md: "2", lg: "1" }
    },
    lineGraph: {
      colSpan: { base: "2", md: "2", lg: "1" }
    },
    divider: {
      w: "92%",
      color: "gray.200"
    },
    chartStyles: {
      w: { base: "100vw", lg: "49.5vw" },
      height: "37vh",
      backgroundColor: "white",
      borderRadius: 8,
      p: 10
    }
  };

  const devices_count = useSelector(
    (state) => state?.summary?.total_devices_count
  );
  const active_devices_count = useSelector(
    (state) => state?.summary?.active_devices_count
  );
  const recently_added_devices = useSelector(
    (state) => state?.summary?.recently_added_devices
  );
  const recently_updated_devices = useSelector(
    (state) => state?.summary?.recently_updated_devices
  );

  const customer_count = useSelector(
    (state) => state?.summary?.recent_sellers_added?.length
  );
  const sold_devices_count = useSelector(
    (state) => state?.summary?.total_devices_sold
  );
  const models_count = useSelector(
    (state) => state?.summary?.total_device_models_added
  );

  const type_count = useSelector(
    (state) => state?.summary?.total_device_type_added
  );

  const customer_team_members_added = useSelector(
    (state) => state?.summary?.recent_sellers_added
  );
  // const customer_team_members_updated = useSelector(
  //   (state) => state?.summary?.recently_updated_customers,
  // );

  console.log("summaryData", summaryData);

  useEffect(() => {
    setLoading(true);

    if(user_type === "Manufacturer"){
      Promise.all([
        dispatch(getDashboardDataAPI()),
        dispatch(getOrganizationsAPI()),
        dispatch(getDeviceAPI())
      ])
        .then(() => {
          setLoading(false);
          // Data for Summary
  
          // Total Product Types
  
          // Get unique device types
          // const uniqueDeviceTypes = new Set(
          //   devices?.map((device) => device?.device_type.id)
          // );
  
          // // Count unique device types
          // const uniqueDeviceTypesCount = uniqueDeviceTypes.size;
  
          // console.log(`Unique device types count: ${uniqueDeviceTypesCount}`);
  
          // setProductTypeCnt(uniqueDeviceTypesCount);
  
          // Total Count of Unique Models
  
          // Get unique models
          // const uniqueModels = new Set(
          //   devices?.map((device) => device?.device_model.id)
          // );
  
          // // Count unique device types
          // const uniqueModelsCount = uniqueModels?.size;
  
          // console.log(`Unique model count: ${uniqueModelsCount}`);
  
          // setModelsCnt(uniqueModelsCount);
  
          // Total Count of Customers
  
          // Get unique models
          // const uniqueCustomers = new Set(
          //   devices?.map((device) => device?.assigned_to_seller_organization?.id)
          // );
  
          // // Count unique device types
          // const uniqueCustomersCount = uniqueModels?.size;
  
          // console.log(`Unique customer count: ${uniqueCustomersCount}`);
  
          // setCustomersCnt(uniqueCustomersCount);
  
          // End: Data for Summary
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    } else{
      Promise.all([
        dispatch(getSellerDashboardDataAPI()),
        dispatch(getSellerOrganizationsAPI()),
        dispatch(getSellerDeviceAPI())
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  return (
    <Box>
      <Loader loading={loading} />
      <Breadcrumbs />
      <Grid {...styles.topGrid}>
        <GridItem {...styles.topContainerWatchlist}>
          {summaryData && (
            <DashboardUserAssetCountSection
              device_count={devices_count}
              active_devices_count={active_devices_count}
              sold_devices_count={sold_devices_count}
              customer_count={customer_count}
              models_count={models_count}
              product_type_count={type_count}
            />
          )}

          <Text mt={8}> Device Status Watch </Text>
          <DeviceWatchlist device_list={recently_updated_devices} />
        </GridItem>
        {/* <GridItem {...styles.sideList}>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Recent Devices</Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <DashboardDeviceList
                recent_devices={recently_added_devices}
                is_update={false}
              />
            </CardBody>
          </Card>
        </GridItem> */}
        {/* <GridItem {...styles.sideList}>
          <ReactECharts option={options} style={styles.chartStyles} />
        </GridItem> */}
        <GridItem {...styles.sideList} hidden>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Recent Customers </Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <DashboardCustomerTeamMembers
                customer_team_members_added={customer_team_members_added}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Dashboard;
